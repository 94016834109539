<template>
	<div>
		<div class="display-2 font-weight-thin mb-3" :html="$t('home.search_title')"></div>
		<v-card class="mb-3 pa-5">
   			<p>{{ $t('home.search_text') }}</p>
			<v-row class="mt-5">
				<v-col sm="6" md="4" lg="3">
					<v-combobox
						:items="keywords"
						clearable
						clear-icon="mdi-close"
						@click:clear="clearSearchword"
						color="black"
						:label="$t('home.search_title')"
						hide-no-data
						@blur="selectedItem=null"
			            :loading="isAutocompleteLoading"
			            :search-input.sync="loadKeywords"
			            @update:search-input="updateSearchWord"
			            return-object
			            :menu-props="{closeOnClick: false, closeOnContentClick: true}"
			            outlined
			            dense
					>
						<v-icon
					      slot="append"
					      color="green"
					      @click="searchActions"
					    >
					      mdi-magnify
					    </v-icon>
					</v-combobox>
				</v-col>
			</v-row>
   		</v-card>
   		<v-card class="mb-3 pa-5" v-if="actionslist">
    		<div class="my-5" v-if="searchword">
    			{{ $t('search.found1')}} "<strong>{{ searchword }}</strong>" {{ $t('search.found2')}} <span class="text-grey">{{ found }}</span> {{ $t('search.found3') }}.
    		</div>
    		<v-card v-for="(thema, index) in actionslist" :key="index" outlined class="mb-1">
    			<v-card-title class="title-grey">{{ thema.title }}</v-card-title>
    			<v-card-text class="pt-3">
    				<div v-for="(action, index1) in thema.actions" :key="index1">
	    				<h4>{{ action.title }}</h4>
	    				<div v-if="action.description" v-html="action.description"></div>
	    			</div>
    			</v-card-text>
    		</v-card>
		</v-card>
		<v-overlay :value="overlay">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</div>
</template>
<script>
import axios from 'axios';

export default {
	name: 'Search',
	data(){
		return {
			searchword: '',
			overlay: false,
			actionslist: false,
			found: 0,
			keywords: [],
	        isAutocompleteLoading: false,
			loadKeywords: null,
			awaitingSearch: false,
			selectedItem: null,
		}
	},
	mounted() {
    document.title = this.$t('home.search_title') + ' - ' + this.$t('general.page_title');
		this.searchword = this.$route.query.q;
		if(this.searchword) {
			this.searchActions();
		}
	},
	methods: {
		searchActions () {
	    	this.overlay = true;
	        if(this.searchword.length > 2) {
	        	let that = this;
	        	let searchword = this.searchword;
	        	axios.post('/api/searchActions', {
	        		searchword: this.searchword,
	        		lang:this.$i18n.locale
				})
				.then(response => {
					that.searchword = searchword;
					if(response.data.action == 'ok') {
						that.actionslist = response.data.response;
						that.found = response.data.found;
					} else {
						that.actionslist = [];
						that.found = 0;
					}
					that.overlay = false;
				})
				.catch(function() {
					that.actionslist = [];
					that.overlay = false;
				});
	        } else {
	        	this.overlay = false;
	        }
		},
        clearSearchword () {
			this.searchword = '';
			this.selectedItem = null;
			this.actionslist = null;
		},
		updateSearchWord(currentValue){
            this.searchword = currentValue;
            if(currentValue) {
				if(this.selectedItem == currentValue) {
					this.searchActions();
				} else {
					this.selectedItem = currentValue;	
				}
            }
        },
		search: function(e) {
	      if (e.keyCode === 13) {
	        this.searchActions();
	      }      
	    },
	},
	computed: {
	},
	watch: {
	  loadKeywords(value) {
		  if (value && value.length > 2) {
			  if (!this.awaitingSearch) {
		          setTimeout(() => {
		        	  this.isAutocompleteLoading = true;
		        	  axios.post('/api/getKeywords', {
		        		searchword: value,
		        		lang:this.$i18n.locale
  					  })
					  .then(response => {
						this.keywords = response.data;
						this.isAutocompleteLoading = false;
						this.awaitingSearch = false;
					  });
					  
		          }, 400);
		        }
		        this.awaitingSearch = true;
		  }			
	  },
    }
}
</script>
<style lang="scss" scoped>
	.title-grey { background: #E4E4E4;}
</style>