<template>
	<div>
		<v-container fluid>
			<v-row align="center" justify="center">
				<v-col cols="12" sm="12" md="8" lg="6">
					<h1 v-show="title" class="display-2 mb-4 font-weight-thin">{{ title }}</h1>
					<div v-show="content" v-html="content"></div>
				</v-col>
			</v-row>
		</v-container>
		<v-overlay :value="overlay">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	data: () => ({
		title: null,
		content: null,
		overlay: true,
	}),
	mounted() {
		axios.post('/api/getPage', {lang:this.$i18n.locale, slug:this.$route.params.slug})
		.then(response => {
			if(response.data.action=='ok') {
				this.title = response.data.title;
        document.title = this.title + ' - ' + this.$t('general.page_title');
				this.content = response.data.content;
				this.overlay = false;
			} else {
				window.location.href = "/"+this.$i18n.locale;
			}
		})
		.catch(function() {
			window.location.href = "/"+this.$i18n.locale;
		});
	}
};
</script>

<style scoped>
.bg-container {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: url( '../assets/bg.jpg') no-repeat center center;
	background-size: cover;
	opacity: 0.2;
}
.bg-green { background: transparent linear-gradient(180deg, #44A155 0%, #0B7A20 100%) 0% 0% no-repeat padding-box;}
.yellow-btn {color:#404040 !important;background:#FED82B !important;}
.yellow-text {color:#FED82B !important;}
.v-application .green--text {color: #0B7A20 !important;}
.position-inherit {position: inherit;}
</style>
