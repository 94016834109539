import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Fragen from './views/Fragen.vue';
import Ergebnisse from './views/Ergebnisse.vue';
import Siegel from './views/Siegel.vue';
import Login from './views/Login.vue';
import ResetPassword from './views/ResetPassword.vue';
import ResetPasswordForm from './views/ResetPasswordForm.vue';
import Register from './views/Register.vue';
import Validation from './views/Validation.vue';
import Unternehmen from './views/Unternehmen.vue';
import Page from './views/Page.vue';
import Profile from './views/Profile.vue';
import Search from './views/Search.vue';
import RouterView from "./components/RouterView.vue";

import { i18n } from "./main.js";

Vue.use(Router)

let router = new Router({
	mode: 'history',
	//base: process.env.BASE_URL,
	routes: [
		{
			path: "/:lang",
			component: RouterView,
			beforeEnter(to, from, next) {
				const lang = to.params.lang;
				if (!["de","pl","cz","en"].includes(lang)) return next("de");
				if (i18n.locale !== lang) {
					i18n.locale = lang;
				}
				return next();
			},
			children: [
				{
					path: '/',
					name: 'home',
					meta: { layout: "home" },
					component: Home
				},
				{
					path: 'fragen/:type',
					name: 'fragen',
					meta: {	layout: "check" },
					component: Fragen
				},
				{
					path: 'ergebnisse',
					name: 'ergebnisse',
					meta: { layout: "check" },
					component: Ergebnisse
				},
				{
					path: 'siegel',
					name: 'siegel',
					meta: { layout: "app" },
					component: Siegel
				},
				{
					path: 'profile',
					name: 'profile',
					meta: { layout: "app" },
					component: Profile
				},
				{
					path: 'unternehmen/:uuid',
					name: 'unternehmen',
					meta: { layout: "check" },
					component: Unternehmen
				},
				{
					path: 'confirm-check/:uuid',
					name: 'confirm-check',
					meta: { layout: "check" },
					component: Validation
				},
				{
					path: 'login',
					name: 'login',
					meta: { layout: "app" },
					component: Login
				},
				{
					path: 'reset-password',
					name: 'reset-password',
					meta: { layout: "app" },
					component: ResetPassword
				},
				{
					path: 'password/reset/:token',
					name: 'reset-password-form',
					meta: { layout: "app" },
					component: ResetPasswordForm
				},
				{
					path: 'register',
					name: 'register',
					meta: { layout: "app" },
					component: Register
				},
				{
					path: 'search',
					name: 'search',
					meta: { layout: "check" },
					component: Search,
					props: route => ({ query: route.query.q })
				},
				{
					path: ':slug', 
					name: 'page',
					meta: { layout: "home"},
					component: Page
				}
			]
		},
		{
			path: "*",
			redirect: "/de"
		}
	]
})

export default router