<template>
	<div>
		<v-card>
			<v-card-title><div class="headline">{{ $t('resetPassword.header') }}</div></v-card-title>
			<v-card-text class="pt-4">
				<div v-if="message" v-html="message"></div>
				<div v-else>
					<v-form v-model="valid" ref="form">
						<v-alert type="error" outlined v-if="error_msg">
							{{ error_msg }}
						</v-alert>
						<v-text-field
							:label="$t('resetPassword.label_email')"
							v-model="email"
							:rules="emailRules"
							required
						></v-text-field>
						<v-text-field
							:label="$t('resetPassword.label_password')+' *'"
							v-model="password"
							type="password"
							:rules="[passwordRules]"
						></v-text-field>
						<v-text-field
							:label="$t('resetPassword.label_password1')+' *'"
							v-model="password1"
							type="password"
							:rules="[confirmPasswordRules]"
						></v-text-field>
						<v-layout>
							<v-btn @click="send" class="primary mt-3">{{ $t('resetPassword.btn_reset_password') }}</v-btn>
						</v-layout>
					</v-form>
				</div>
			</v-card-text>
			<v-divider class="mx-4"></v-divider>
			<v-card-text class="pt-4">
				<v-btn text small :href="'/'+$i18n.locale+'/login'" color="green" class="mt-3">&laquo; {{ $t('resetPassword.back') }}</v-btn>
			</v-card-text>
		</v-card> 
	</div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex'

export default {
	components: {
	},
	data: () => ({
		error_msg: '',
		valid: false,
		email: '',
		emailRules: [],
		message: null,
		password: '',
		password1: '',
		token: '',
	}),
	methods: {
		send: function () {
			if (this.$refs.form.validate()) {
				axios.post('/api/save-password', {
					email: this.email,
					password: this.password,
					password_confirmation: this.password1,
					token: this.token,
					lang:this.$i18n.locale
				})
				.then(res=>{
					if(res.data.action==='ok') {
						this.message = res.data.message;	
					}
					if(res.data.action==='error') {
						this.error_msg = res.data.message;	
					}
				})
				.catch(function() {
					this.error_msg = this.$t('login.error_msg');
					this.error = true;
					this.verify_btn = false;
				});
			}
		},
	},
	mounted() {
		if(this.logged) {
			this.$router.push('/'+this.$i18n.locale+'/login')
		}
		this.token = this.$route.params.token;
		this.emailRules = [
			(v) => !!v || this.$t('register.emailRules1'),
			(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('register.emailRules2')
		];
	},
	computed: {
		...mapState(['logged']),
		passwordRules() {
			return (this.password? /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(this.password) : true) || this.$t('register.passwordRules2');
		},
		confirmPasswordRules() {
			return this.password1 === this.password || this.$t('resetPassword.confirmPasswordRule');
		}
	},
};
</script>
