import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n'
import messages from './lang'
import HomeLayout from "./layouts/HomeLayout.vue";
import AppLayout from "./layouts/AppLayout.vue";
import CheckLayout from "./layouts/CheckLayout.vue";

import "leaflet/dist/leaflet.css";
import "typeface-roboto/index.css";

Vue.config.productionTip = false
Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages
});

Vue.component("app-layout", AppLayout);
Vue.component("home-layout", HomeLayout);
Vue.component("check-layout", CheckLayout);

axios.interceptors.request.use(
	(config) => {
		let token = localStorage.getItem('access_token');

		if (token) {
		//Nur Lokal
		//var basicAuth = '';
		//var basicAuth = 'Basic ' + btoa('test:test') + ',';
			config.headers.common['Authorization'] = `Bearer ${ token }`;
		}

		return config;
	}, 

	(error) => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use( (response) => {
	// Return a successful response back to the calling service
	return response;
}, (error) => {
	if (error.response.status !== 401) {
		return new Promise((resolve, reject) => {
			reject(error);
		});
	}
	if (error.response.status === 401) {
		localStorage.removeItem('access_token');
		localStorage.removeItem('name');
		localStorage.removeItem('logged');
		store.dispatch('setAccessToken');
		store.dispatch('setLogged');
		return router.push('/'+i18n.locale+'/login')
	}
});

Vue.use(VueAxios, axios)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
