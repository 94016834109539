<template>
	<div>
		<v-card class="bg-green mb-10">
			<div class="bg-container"></div>
			<v-card-text class="py-10 text-center white--text position-inherit">
				<h1 :class="[$vuetify.breakpoint.smAndUp ? 'white--text  display-3 font-weight-bold mt-10 mb-4 text-uppercase' : ' white--text display-1 font-weight-bold mt-10 mb-4 text-uppercase']" v-html="$t('home.header')"></h1>
				<h1 :class="[$vuetify.breakpoint.smAndUp ? 'display-1 font-weight-bold mt-10 mb-4 text-uppercase yellow-text' : 'title font-weight-bold mt-10 mb-4 text-uppercase yellow-text']" v-html="$t('home.subheader')"></h1>
				<div class="mb-10 body-1 px-5 font-weight-light white--text">
					{{ $t('home.text1') }}<br>
					{{ $t('home.text2') }}<br>
					{{ $t('home.text3') }}
				</div>
				<div class="d-inline-flex flex-column flex-md-row justify-center">
					<v-btn :href="'/'+$i18n.locale+'/fragen/ag'" x-large class="yellow-btn title text-uppercase mb-3 mr-md-10">{{ $t('home.button_ag') }}</v-btn>
					<v-btn
              :disabled="!!logged"
              :href="'/'+$i18n.locale+'/fragen/an'" x-large class="yellow-btn title text-uppercase mb-3" >{{ $t('home.button_an') }}</v-btn>
				</div>
			</v-card-text>
		</v-card>
    <v-row justify="center" class="no-gutters">
      <v-col cols="12" md="10" lg="8">
        <v-row class="mt-6 px-4 no-gutters">
          <v-col cols="12" sm="6">
            <v-img
                aspect-ratio="1.33"
                :src="require('../assets/Familie.jpg')"
            ></v-img>
          </v-col>
          <v-col cols="12" sm="6" class="px-4">
            <h2 class="mb-4" v-html="$t('home.product_title')"></h2>

            <p>{{ $t('home.product_description') }}</p>
            <div class="overflow-auto">
            <v-img  class="mb-2 float-left"
                    width="100"
                    aspect-ratio="1.1"
                    alt=""
                    :src="require('../assets/jetzt_neu.png')"
            ></v-img>
            <v-img  class="mb-2 ml-2 mt-5 float-left"
                    width="158"
                    height="50"
                    alt=""
                    :src="require('../assets/Logo_NoSa_innovativ.png')"
            ></v-img>
            </div>
            <p v-html="$t('home.product_description2')"></p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="green white--text">
      <h2 class="text-center mt-6 pt-6">{{ $t('home.search_title') }}</h2>
      <div class="text-center my-5">
      <p style="max-width: 80ch; margin: 0 auto;">{{ $t('home.search_text') }}</p>
      <v-row justify="center" class="no-gutters">
        <v-col sm="6" md="4" lg="3">
          <v-combobox
              :items="keywords"
              class="mx-4"
              clearable
              clear-icon="mdi-close"
              @click:clear="clearSearchword"
              color="white"
              dark
              :label="$t('home.search_title')"
              hide-no-data
              @blur="selectedItem=null"
              v-on:keyup="search"
              :loading="isAutocompleteLoading"
              :search-input.sync="loadKeywords"
              @update:search-input="updateSearchword"
              return-object
              :menu-props="{closeOnClick: false, closeOnContentClick: true}"
              outlined
              dense
          >
            <v-icon
                slot="append"
                color="white"
                @click="searchActions"
                aria-label="$t('home.search')"
            >
              mdi-magnify
            </v-icon>
          </v-combobox>
        </v-col>
      </v-row>
    </div>
    </div>
    <h2 class="pb-4 text-center">{{ $t('home.timeline_title') }}</h2>
    <v-row justify="center" class="px-4 no-gutters">
      <v-col cols="12" md="10" lg="8">
        <v-timeline
            align-top
            :dense="$vuetify.breakpoint.xsOnly"
        >
          <v-timeline-item fill-dot>
            <template v-slot:icon>
              <span class="counter">1</span>
            </template>
            <v-card class="elevation-2" dark color="primary darken-1">
              <v-card-title class="headline">
                {{ $t('home.timeline_title1') }}
              </v-card-title>
              <v-card-text class="pt-4 white text--primary">
                 {{ $t('home.timeline_description1') }}
               </v-card-text>
            </v-card>
          </v-timeline-item>

          <v-timeline-item fill-dot>
            <template v-slot:icon>
              <span class="counter">2</span>
            </template>
            <v-card class="elevation-2" dark color="primary darken-1">
              <v-card-title class="headline">
                {{ $t('home.timeline_title2') }}
              </v-card-title>
              <v-card-text class="pt-4 white text--primary">
                {{ $t('home.timeline_description2') }}
              </v-card-text>
            </v-card>
          </v-timeline-item>

          <v-timeline-item fill-dot>
            <template v-slot:icon>
              <span class="counter">3</span>
            </template>
            <v-card class="elevation-2" dark color="primary darken-1">
              <v-card-title class="headline">
                {{ $t('home.timeline_title3') }}
              </v-card-title>
              <v-card-text class="pt-4 white text--primary">
                {{ $t('home.timeline_description3') }}
              </v-card-text>
            </v-card>
          </v-timeline-item>

          <v-timeline-item fill-dot>
            <template v-slot:icon>
              <span class="counter">4</span>
            </template>
            <v-card class="elevation-2" dark color="primary darken-1">
              <v-card-title class="headline">
                {{ $t('home.timeline_title4') }}
              </v-card-title>
              <v-card-text class="pt-4 white text--primary">
                {{ $t('home.timeline_description4') }}
              </v-card-text>
            </v-card>
          </v-timeline-item>

          <v-timeline-item fill-dot>
            <template v-slot:icon>
              <span class="counter">5</span>
            </template>
            <v-card class="elevation-2" dark color="primary darken-1">
              <v-card-title class="headline">
                {{ $t('home.timeline_title5') }}
              </v-card-title>
              <v-card-text class="pt-4 white text--primary">
                {{ $t('home.timeline_description5') }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
       </v-col>
    </v-row>
    <v-row justify="center" no-gutters class="px-4">
      <v-col cols="12" md="10" lg="8" style="max-width: 1200px">
        <h2 class="text-center my-6">{{ $t('home.certificate_title') }}</h2>
        <v-row class="no-gutters" style="align-items: flex-end">
          <v-col sm="4" class="mb-4">
            <v-row justify="center" align="start" class="flex-row-reverse flex-sm-row">
              <v-col cols="4" sm="6" no-gutters class="py-0">
                <v-img
                    :src="require('../assets/bsw_FamilyManager-Siegel_bronze.jpg')"
                    class="white--text align-end"
                ></v-img>
              </v-col>
              <v-col cols="8" sm="12" class="py-0">
                <v-card elevation="0" class="certification">
                  <v-card-title class="text-uppercase justify-center">
                    {{ $t('home.certificate_subtitle1') }}
                  </v-card-title>
                  <v-card-text>
                    {{ $t('home.certificate_description1') }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="4" class="mb-4">
            <v-row justify="center" align="start" class="flex-row-reverse flex-sm-row">
              <v-col cols="4" sm="8" no-gutters class="py-0">
                <v-img
                  :src="require('../assets/bsw_FamilyManager-Siegel_silber.jpg')"
                  class="white--text align-end"
                ></v-img>
              </v-col>
              <v-col cols="8" sm="12" class="py-0">
                <v-card elevation="0" class="certification">
                  <v-card-title class="text-uppercase justify-center">
                    {{ $t('home.certificate_subtitle2') }}
                  </v-card-title>
                  <v-card-text>
                    {{ $t('home.certificate_description2') }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="4" class="mb-4">
            <v-row justify="center" align="start" class="flex-row-reverse flex-sm-row">
              <v-col cols="4" sm="10" no-gutters class="py-0">
              <v-img
                  :src="require('../assets/bsw_FamilyManager-Siegel_gold.jpg')"
                  class="white--text align-end"
              ></v-img>
              </v-col>
              <v-col cols="8" sm="12" class="py-0">
                <v-card elevation="0" class="certification">
                  <v-card-title class="text-uppercase justify-center">
                    {{ $t('home.certificate_subtitle3') }}
                  </v-card-title>
                  <v-card-text>
                    {{ $t('home.certificate_description3') }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters class="px-4 mb-10">
      <v-col cols="12" md="10" lg="8">
        <h2 class="text-center my-6">{{ $t('home.contact')}}</h2>
        <v-row justify="center">
          <v-col cols="12" sm="6">
            <v-card elevation="0">
              <v-row class="pa-4">
                <v-col cols="12" md="6">
                  <v-img :src="require('../assets/Julius_Kunath.jpg')" alt=""></v-img>
                </v-col>
                <v-col class="text-center text-sm-left">
                  <h3>Julius Kunath</h3>
                  <p class="text-caption">{{ $t('home.jk_position')}} pas <span class="font-italic">digital</span></p>
                  <p><strong class="text-caption text--secondary">{{ $t('home.phone_number') }}</strong><br>
                    <a href="tel:+493514250294">+49 351 42502-94</a>
                  </p>
                  <p><strong class="text-caption text--secondary">{{ $t('home.email') }}</strong><br>
                    <a href="mailto:julius.kunath@bsw-mail.de">julius.kunath@bsw-mail.de</a></p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-form @submit="filterCompanies" class="px-4 green theme--dark">
      <v-row justify="center" class="px-4">
        <v-col cols="12">
          <h2 class="mt-6 text-center white--text">{{ $t('home.certified_companies') }}</h2>
        </v-col>
        <v-col cols="12" md="10" lg="8">
          <v-row class="ma-0">
            <v-col class="flex-sm-grow-1" cols="12" xs="12" sm="auto">
              <v-select
                      v-model="companySize"
                      :items="companySizes"
                      item-text="title"
                      item-value="id"
                      :label="$t('fragen.label_companySize')"
                      dark
                      @change="filterCompanies"
              ></v-select>
            </v-col>
            <v-col class="flex-grow-1" cols="12" xs="12" sm="auto">
              <v-select required
                        v-model="branche"
                        :items="branches"
                        item-text="title"
                        item-value="id"
                        :label="$t('fragen.label_branche')"
                        dark
                        @change="filterCompanies"
              ></v-select>
            </v-col>
            <v-col class="d-sr-only" cols="auto">
              <v-btn type="submit">{{ $t('home.show_results') }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="center" class="green no-gutters">
      <v-col cols="12" md="10" lg="8" class="pb-16">
        <div style="height: 400px;">
          <l-map
              :options="{ scrollWheelZoom:false }"
              :center="map.center"
              style="height: 100%"
              ref="leafmap"
          >
            <l-tile-layer :url="map.url"/>
            <div v-if="companies.length > 0">
              <l-marker :lat-lng="latLng(company.latitude, company.longitude)"
                        v-for="company in companies"
                        :key="company.name"
                        :icon="icon({ iconUrl: '/img/icon.svg', iconSize: [72,56], iconAnchor: [0,56] })"
              >
                <l-popup>
                    <v-row class="flex-nowrap">
                      <v-col>
                        <v-img v-if="company.img"
                               :src="company.img"
                               alt=""
                               max-width="75"
                               eager
                        ></v-img>
                      </v-col>
                      <v-col>
                        <strong>{{company.name}}</strong><br>
                        <div class="py-2" v-html="company.address"></div>
                        <v-btn class="green white--text" :href="company.url" v-text="$t('profile.header')"></v-btn>
                      </v-col>
                    </v-row>
                </l-popup>
              </l-marker>
            </div>
          </l-map>
        </div>

      </v-col>
    </v-row>
    <h2 class="text-center mt-6">{{ $t('home.partners') }}</h2>
    <v-row justify="center" no-gutters class="px-4 mb-6">
      <v-col cols="12" md="10" lg="8">
        <v-carousel hide-delimiters height="auto" class="py-6">
          <template v-for="(partner, index) in slider">
            <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
              <v-row justify="space-around" class="px-12 flex-nowrap">
                <template v-for="(n,i) in columns">
                  <template v-if="(+index + i) < slider.length">
                    <v-col justify="space-around" md="6" lg="4" xl="3" cols="12" :key="i">
                      <v-img class="align-self-center"
                              height="80"
                              contain
                              v-if="(+index + i) < slider.length"
                              :alt="slider[+index + i].name"
                              :src="slider[+index + i].logo"
                             style="width: auto"
                      >
                      </v-img>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-carousel-item>
          </template>
        </v-carousel>
      </v-col>
    </v-row>
	</div>
</template>
<script>
import axios from 'axios';
import { latLng, icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
// eslint-disable-next-line no-unused-vars
import router from "../router";
import {mapState} from "vuex";

export default {
  components: { LMap, LTileLayer, LMarker, LPopup },
	data(){
		return {
			searchword: '',
			keywords: [],
	        isAutocompleteLoading: false,
			loadKeywords: null,
			awaitingSearch: false,
			selectedItem: null,
      companies: [],
      ziplist: [],
      locations: [],
      branches: [],
      companySizes : [],
      zip: '',
      location: '',
      branche: '',
      companySize: '',
      map: {
        center: latLng(51.05703, 13.72782),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 13
      },
      slider: []
		}
	},
  computed: {
    ...mapState(['logged', 'name']),
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    }
  },
	mounted() {
    document.title = this.$t('general.page_title');
		localStorage.removeItem('lastCheck');
		localStorage.removeItem('lastAnswers');
		localStorage.removeItem('lastOptions');

    axios.post('/api/getOptionsForMap', {lang:this.$i18n.locale})
        .then(response => {
          this.branches = [{'title' : this.$t('home.all') }].concat(response.data.branches);
          this.companySizes = [{'title' : this.$t('home.all') }].concat(response.data.companysizes);
        });

    axios.post('/api/getCompanies', {
      companysize: this.companySize,
      branche: this.branche,
    },).then(response => {
      this.companies = response.data.companies;
      if (response.data.latitude > 0 && response.data.longitude > 0) {
        this.map.center = latLng(response.data.latitude, response.data.longitude);
        this.$refs.leafmap.mapObject.fitBounds(response.data.bounds);
      }
    });

    axios.post('/api/getSlider').then(response => {
      this.slider = response.data.slider;
    })
	},
	methods: {
    icon,
    latLng,
	  filterCompanies() {
      axios.post('/api/getCompanies', {
        companysize: this.companySize,
        branche: this.branche,
      })
      .then(response => {
        this.companies = response.data.companies;
        if (response.data.latitude > 0 && response.data.longitude > 0) {
          this.$refs.leafmap.mapObject.flyTo(latLng(response.data.latitude, response.data.longitude));
          this.map.center = latLng(response.data.latitude, response.data.longitude);
          this.$refs.leafmap.mapObject.fitBounds(response.data.bounds);
        }
      });
    },
		updateSearchword(currentValue){
            this.searchword = currentValue;
            if(currentValue) {
				if(this.selectedItem == currentValue) {
					this.searchActions();
				} else {
					this.selectedItem = currentValue;	
				}
            }
        },
		searchActions () {
        	if(this.searchword){
        		this.$router.push({
        		    path: '/'+this.$i18n.locale+'/search',
        		    query: {
        		      q: this.searchword,
        		      t: new Date().getTime(),
        		    }
				});
        	}
		},
		search: function(e) {
	      if (e.keyCode === 13) {
	        this.searchActions();
	      }      
	    },
	    clearSearchword () {
			this.searchword = '';
			this.selectedItem = null;
		},
	},
	watch: {
    '$i18n.locale' : function() {
      axios.post('/api/getOptionsForMap', {lang:this.$i18n.locale})
          .then(response => {
            this.branches = [{'title' : this.$t('home.all') }].concat(response.data.branches);
            this.companySizes = [{'title' : this.$t('home.all') }].concat(response.data.companysizes);
          });
    },
	  loadKeywords(value) {
		  if (value && value.length > 2) {
			  if (!this.awaitingSearch) {
		          setTimeout(() => {
		        	  this.isAutocompleteLoading = true;
		        	  axios.post('/api/getKeywords', {
		        		searchword: value,
		        		lang:this.$i18n.locale
  					  })
					  .then(response => {
						this.keywords = response.data;
						this.isAutocompleteLoading = false;
						this.awaitingSearch = false;
					  });
					  
		          }, 400);
		        }
		        this.awaitingSearch = true;
		  }			
	  },
    }
};
</script>
<style scoped>
.bg-container {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: url( '../assets/bg.jpg') no-repeat center center;
	background-size: cover;
	opacity: 0.2;
}
.bg-green { background: transparent linear-gradient(180deg, #44A155 0%, #0B7A20 100%) 0% 0% no-repeat padding-box;}
.yellow-btn {color:#404040 !important;background:#FED82B !important;}
/deep/ .yellow-btn .v-btn__content { white-space: normal !important; flex: auto !important; }
.yellow-text {color:#FED82B !important;}
.v-application .green--text {color: #0B7A20 !important;}
.position-inherit {position: inherit;}
.theme--light.v-list-item .v-list-item__mask {
    color: #218233 !important;
    background: transparent !important;
}
.counter {
  background: white;
  border-radius: 50%;
  display: inline-block;
  height: 1.75rem;
  line-height: 1.75rem;
  text-align: center;
  width: 1.75rem;
}

/deep/ .v-slide-group__next .v-icon,
/deep/ .v-slide-group__prev .v-icon {
  color: #0B7A20;
}

/deep/ .v-window__prev,
/deep/ .v-window__next {
  background: #0B7A20;
}
</style>
<style>
.header.v-toolbar,
.v-menu__content {
  z-index: 1001 !important;
}

h2 {
  font-size: 2.125rem;
  font-weight: 100;
}

body {
  font-weight: 300;
}

.v-application .headline {
  font-weight: 100;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 300;
}

.certification {
  margin-bottom: -15px;
}

@media (min-width: 600px) and (max-width: 959px) {
  .certification {
    min-height: 260px;
  }
}

@media (min-width: 960px) and (max-width: 1263px) {
  .certification {
    min-height: 215px;
  }
}

@media (min-width: 1264px) and (max-width: 1903px) {
  .certification {
    min-height: 190px;
  }
}

@media (min-width: 1904px) {
  .certification {
    min-height: 170px;
  }
}
</style>

