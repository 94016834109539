<template>
	<div>
		<div v-if="list">
			<h1 class="display-2 font-weight-thin mb-3">Ergebnisse</h1>
			<div v-if="checks">
				<v-row v-for="item in checks" :key="item.id" class="mb-1 white rounded" no-gutters>
					<v-col class="col-8 col-sm-4 black--text px-3 align-self-center">{{ item.datum }}</v-col>
					<v-col class="col-4 col-sm-2 align-self-center text-right text-sm-left">
						<v-progress-circular
							class="col mr-2 my-2"
							:rotate="-90"
							:size="50"
							:width="5"
							:value="item.results.total.percent"
							:color="(parseInt(item.results.total.percent)<50? 'red' : (parseInt(item.results.total.percent)<70? 'orange' : 'primary'))"
						>
							<span class="text-center">{{ item.results.total.percent }}</span>
						</v-progress-circular>
					</v-col>
					<v-col class="col-12 col-sm-6 text-left text-sm-right pa-2 align-self-center">
						<v-btn small class="mr-2 green--text" @click="showCheck(item.id)">Anzeigen</v-btn>
						<v-btn small class="mr-2" color="error" @click="openDialog(item.id)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
					</v-col>
				</v-row>
			</div>
			<div v-else>Sie haben keine Ergebnisse gespeichert</div>
			<v-dialog v-model="dialog" persistent max-width="300">
				<v-card class="danger">
					<v-card-title class="headline">Ergebnis entfernen</v-card-title>
					<v-card-text >Wollen Sie das Ergebnis von <span class="text-no-wrap">{{ datum }}</span> wirklich entfernen?</v-card-text>
					<v-card-actions>
						<div class="flex-grow-1"></div>
						<v-btn color="green darken-1" text @click="closeDialog">Nein</v-btn>
						<v-btn color="red darken-1" text @click="deleteCheck">Ja, entfernen</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-snackbar v-model="snackbar" :color="snackbarColor" top :timeout="2000">
        {{ snackbarText }}
        <v-btn color="white" text @click="snackbar = false">
          <v-icon>mdi-close-box</v-icon>
      </v-btn>
      </v-snackbar>
		</div>
		<div v-else>
			<h1 class="display-2 font-weight-thin mb-3">Ergebnis vom {{ datum }} Uhr</h1>
      <h2>Handlungsempfehlung für Arbeitgeber</h2>
			<div v-if="results">
			  <v-banner sticky elevation="2" class="white mb-3 rounded" mobile-breakpoint="355">
					<span class="headline align-self-center">{{ $t('fragen.gesamtergebnis') }}</span>
					<template v-slot:actions class="justify-end ml-0">
						<v-progress-circular
							class="my-1 align-self-center"
							:rotate="-90"
							:size="60"
							:width="5"
							:value="results.total.percent"
							:color="(parseInt(results.total.percent)<50? 'red' : (parseInt(results.total.percent)<70? 'orange' : 'primary'))"
						>
							<span class="text-center">{{ $t('fragen.ist') }}<br>{{ results.total.percent }}</span>
						</v-progress-circular>
						<v-progress-circular
							class="my-1 align-self-center"
							:rotate="-90"
							:size="60"
							:width="5"
							:value="plan"
							:color="(parseInt(plan)<50? 'red' : (parseInt(plan)<70? 'orange' : 'primary'))"
							disabled
						>
							<span class="text-center">{{ $t('fragen.plan') }}<br>{{ plan }}</span>
						</v-progress-circular>
					</template>
				</v-banner>
        <p>{{ $t('fragen.hinweis') }}</p>
				<v-expansion-panels accordion focusable :multiple="multiple">
					<v-expansion-panel v-for="(item,i) in results.themen" :key="i">
						<v-expansion-panel-header style="padding-right: 15px">
							<div class="row">
								<div class="headline font-weight-light col">{{item.title}}</div>
								<div class="mr-5">
									<v-checkbox v-model="in_pdf" label="in PDF" :value="item.title" v-on:click.stop=""></v-checkbox>
								</div>
								<div class="mr-5">
									<v-progress-circular
										class="col"
										:rotate="-90"
										:size="50"
										:width="5"
										:value="item.percent"
										:color="(parseInt(item.percent)<50? 'red' : (parseInt(item.percent)<70? 'orange' : 'primary'))"
									>
										{{ item.percent }}
									</v-progress-circular>
								</div>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content v-if="item.actions">
							<v-data-table 
								v-model="selected[i]"
								:headers="headers" 
								:items="item.actions" 
								must-sort
								show-expand 
								show-select
								item-key="id" 
								class="elevation-1 mt-3"
								hide-default-footer
								disable-pagination
								:sort-by="sortby"
								:sort-desc="sortdesc"
                :header-props="headerProps"
							>
								<template v-slot:header.data-table-select="{ header }">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="d-inline-flex">
                        <span class="d-none d-sm-flex mr-1">{{ $t('fragen.plan') }}</span>
                        <v-icon color="green" small>mdi-help-circle</v-icon>
                      </span>
                    </template>
                    <span>{{ $t('fragen.hinweis2') }}</span>
                  </v-tooltip>
								</template>
								<template v-slot:header.title="{ header }">
									<span class="d-none d-sm-flex">{{ header.text }}</span>
								</template>
                <template v-slot:item.title="{ item }">
                  <span class="text-body-1 font-weight-bold text-sm-body-2 font-weight-sm-regular">{{ item.title }}</span>
                </template>
								<template v-slot:item.weighting_factor="{ item }">
									<div class="p-2">
										<v-icon v-if="item.weighting_factor>7">mdi-arrow-up</v-icon>
										<v-icon v-else-if="item.weighting_factor>3">mdi-arrow-right</v-icon>
										<v-icon v-else>mdi-arrow-down</v-icon>
									</div>
								</template>
								<template v-slot:item.aufwand_zeit="{ item }">
									<div class="p-2">
										<v-icon v-if="item.aufwand_zeit==2">mdi-arrow-up</v-icon>
										<v-icon v-else-if="item.aufwand_zeit==1">mdi-arrow-right</v-icon>
										<v-icon v-else>mdi-arrow-down</v-icon>
									</div>
								</template>
								<template v-slot:item.aufwand_finanz="{ item }">
									<div class="p-2">
										<v-icon v-if="item.aufwand_finanz==2">mdi-arrow-up</v-icon>
										<v-icon v-else-if="item.aufwand_finanz==1">mdi-arrow-right</v-icon>
										<v-icon v-else>mdi-arrow-down</v-icon>
									</div>
								</template>
								<template v-slot:expanded-item="{ item }">
									<td :colspan="headers.length" class="pt-3">
										<v-row>
											<v-col cols="12" sm="5">
                        <strong>{{ $t('fragen.description') }}</strong>
                        <div v-html="item.description"></div>
                      </v-col>
											<v-col cols="12" sm="6">
												<strong>{{ $t('fragen.responsible') }}:</strong> {{item.responsible}}<br>
												<strong>{{ $t('fragen.jurisdiction') }}:</strong> {{item.jurisdictions}}<br>
												<div v-if="item.partners">
                          <p class="pt-3">
                            <strong>{{ $t('fragen.partners') }}:</strong>
                          </p>
                          <div v-for="itm in item.partners" :key="itm.id">
                            <p>
                              <strong>{{itm.institution}}</strong><br>
                              <span>{{itm.firstname}} {{itm.lastname}}</span><br>
                              <span>{{itm.email}}</span>
                            </p>
                          </div>
                        </div>
												<div v-if="item.practises" class="font-weight-regular mt-3">Best practise:</div>
												<div v-for="itm in item.practises" :key="itm.id" class="py-2">
													<div v-if="itm.title" class="font-weight-regular">{{itm.title}}</div>
													<div v-if="itm.description" v-html="itm.description" class="practise-descr"></div>
													<v-btn 
														v-for="(media, ind, ind2) in itm.media"
														:key="`${itm.id}-${ind2}`"
														class="my-2 mr-2" 
														outlined color="primary" 
														:href="media.href"
														target="_blank"
													>
														<v-icon v-if="media.ext=='mp4'">mdi-video</v-icon>
														<v-icon v-else-if="media.ext=='mp3'">mdi-volume-low</v-icon>
														<v-icon v-else-if="media.ext=='wav'">mdi-volume-low</v-icon>
														<v-icon v-else-if="media.ext=='pdf'">mdi-file-pdf-box</v-icon>
													</v-btn>
													<v-btn 
														v-if="itm.url"
														class="my-2 mr-2" 
														outlined color="primary" 
														:href="itm.url"
														target="_blank"
													>
														<v-icon>mdi-link</v-icon>
													</v-btn>
												</div>
											</v-col>
										</v-row>
									</td>
								</template>
							</v-data-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<div class="d-flex justify-space-between flex-wrap">
					<v-btn light class="mt-3" @click="closeCheck">Schließen</v-btn>
					<div class="flex-grow-1"></div>
          <div class="d-flex flex-wrap justify-end">
            <v-btn light class="ml-3 mt-3 green--text" @click="savePlan" :disabled="!savePlanEnabled"><v-icon>mdi-content-save</v-icon> Plan speichern</v-btn>
            <v-btn light class="ml-3 mt-3" @click="getCheckPDF"><v-icon>mdi-file-pdf</v-icon>Ausgewählte Optionen / Check (PDF)</v-btn>
            <v-btn light class="ml-3 mt-3" @click="getPDF"><v-icon>mdi-file-pdf</v-icon> Maßnahmen mit Plan (PDF)</v-btn>
          </div>
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex'

export default {
	data: () => ({
		checks: [],
		list: true,
		results: [],
		datum: '',
		dialog: false,
		currentId: '',
		snackbar: false,
		snackbarColor: 'error',
		snackbarText: '',
		expanded: [],
		multiple:true,
    headerProps: {
		  sortByText: "Sortieren nach"
    },
		headers:[],
        selected: [],
        plan: 0,
		in_pdf: [],
        validation: 0,
        siegel: null,
        sortby: ['weighting_factor'],
		sortdesc: [true],
    savePlanEnabled: false,
	}),
	methods: {
    savePlan() {
      axios.post('/api/savePlan', {
        check: this.currentId,
        selected: this.selected
      })
      .then(() => {
        this.savePlanEnabled = false;
      });
    },
    showCheck(id) {
      if (this.checks['c' + id] != undefined) {
        var item = this.checks['c' + id];
        this.datum = item.datum;
        this.selected = [];
        if (Array.isArray(item.results.plan)) {
          this.selected = item.results.plan;
        }
        this.results = item.results;
		  if (this.results.themen) {
			  this.results.themen.forEach( function(item) {
				  this.in_pdf.push(item.title);
			  }, this);
		  }
        this.siegel = item.siegel;
        this.list = false;
        this.currentId = id;
        this.plan = this.results.total.percent;
        this.validation = item.validation;
        this.$nextTick(function(){ this.savePlanEnabled = false; });
      }
    },
    closeCheck() {
      this.list = true;
      this.datum = '';
      this.results = [];
      this.currentId = 0;
      this.validation = 0;
    },
    deleteCheck() {
      this.snackbar = true;
      axios.post('/api/deleteResult', {
        id: this.currentId,
        lang: this.$i18n.locale
      })
          .then(response => {
            if (response.data.action == 'ok') {
              this.$delete(this.checks, 'c' + this.currentId)
              this.dialog = false;
              this.datum = '';
              this.currentId = 0;
              this.snackbarColor = 'success';
              this.snackbarText = response.data.msg;
              this.validation = 0;
            } else {
              this.snackbarText = this.$t('general.error');
              this.snackbarColor = 'error';
              this.showAlert = true;
            }
          })
          .catch(function (error) {
            if (error.response && error.response.status === 401) {
              window.location.href = "/" + this.$i18n.locale + "/login";
            } else {
              this.snackbarText = this.$t('general.error');
              this.snackbarColor = 'error';
              this.showAlert = true;
            }
          });
    },
    openDialog(id) {
      var item = this.checks['c' + id];
      this.datum = item.datum;
      this.currentId = id;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.datum = '';
      this.currentId = 0;
    },
    getPDF() {
      var selected = [];
      this.selected.forEach(function (item) {
        item.forEach(function (action) {
          selected.push(action.id);
        });
      });

		let themen = this.results.themen;

		if (this.in_pdf.length > 0) {
			themen = [];

			this.results.themen.forEach(function (item) {
				if (this.in_pdf.indexOf(item.title) >= 0) {
					themen.push(item);
				}
			}, this);
		}

      axios({
        url: '/api/getPdf',
        method: 'POST',
        responseType: 'blob',
        data: {
          current: this.results.total.percent,
          planned: this.plan,
          company: true,
          themen: themen,
          selected: selected
        }
      })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Family_Manager_Ergebnis';
        link.click()
        URL.revokeObjectURL(link.href)
      });
    },
    getCheckPDF() {
      axios({
        url: '/api/getCheckPdf',
        method: 'POST',
        responseType: 'blob',
        data: {
          check: this.currentId
        }
      })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Family_Manager_Check';
        link.click()
        URL.revokeObjectURL(link.href)
      });
    },
  },
	mounted () {
		if(!this.logged) {
			this.$router.push('/'+this.$i18n.locale+'/login')
		}
    document.title = this.$t('menu.ergebnisse') + ' - ' + this.$t('general.page_title');
		this.headers = [
			{
				text: this.$t('fragen.action'),
				align: 'start',
				sortable: false,
				value: 'title'
			},
			{ text: this.$t('fragen.weighting_factor'), value: 'weighting_factor' },
			{ text: this.$t('fragen.aufwand_zeit'), value: 'aufwand_zeit' },
			{ text: this.$t('fragen.aufwand_finanz'), value: 'aufwand_finanz' },
			{ text: '', value: 'data-table-expand' },
		];
		axios.post('/api/getResults', {lang:this.$i18n.locale})
		.then(response => {
			this.checks = response.data.checks;

			let params = window.location.search.substr(1);
			if (params.indexOf('check') === 0) {
			  let id = params.substring(params.indexOf('=') + 1);
        this.showCheck(id);
      }
		})
		.catch(function(error) {
			if (error.response && error.response.status === 401) {
				window.location.href = "/"+this.$i18n.locale+"/login";
			}
		});
	},
	watch: {
		selected: function() {
      this.savePlanEnabled = true;
			if(this.selected.length) {
				var selected = [];
				this.selected.forEach(function(item){
				  if (Array.isArray(item)) {
            item.forEach(function (action) {
              selected.push(action.id);
            });
          } else {
				    item = [];
          }
				});
				//selected = this.selected.map(function(object) { return object['id'] });
				axios.post('/api/getNewSavedCheckResults', {
					selected: selected,
					id: this.currentId,
					lang:this.$i18n.locale
				})
				.then(response => {
					this.plan = response.data.results.total.percent;
				});
			} else {
				this.plan = this.results.total.percent;
			}
			
		},
	},
	computed: {
		...mapState(['logged']),
	}
};
</script>
<style scoped>
  /deep/ .v-btn {
    max-width: 100%;
  }

  /deep/ .v-btn__content {
    white-space: normal !important;
    flex: auto !important;
  }

	.v-banner--sticky { top: 70px !important;z-index: 2 !important;}
	.v-application .headline {font-size: 1.4rem !important;}
	.text-wrap-normal {white-space: normal}
  /deep/ .v-data-table-header__icon {
    transform: none;
    opacity: 1;
  }

  /deep/ .v-data-table [role="columnheader"]:nth-child(3),
  /deep/ .v-data-table [role="columnheader"]:nth-child(4),
  /deep/ .v-data-table [role="columnheader"]:nth-child(5) {
    width: 12%;
  }

  @media (min-width: 600px) {
    /deep/ .font-weight-sm-regular {
      font-weight: 400 !important;
    }
  }

  /deep/ .v-data-table__mobile-row__cell.v-data-table__mobile-row__cell .text-body-1 {
    display: inline-block;
    padding-right: 50px;
    position: relative;
    text-align: left;
    top: -28px;
  }

  /deep/ .v-data-table__checkbox {
    z-index: 1;
  }
</style>
