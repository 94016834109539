<template>
	<div class="fill-height d-flex flex-column">
		<h1>{{ $t('validation.header') }}</h1>
		<div v-if="questions" class="fill-height d-flex flex-column">
			<h2>{{ $t('validation.company') }}: {{ firma }}</h2>
			<v-carousel v-model="model" hide-delimiters :show-arrows="false" touchless height="auto" class="pb-10">
				<v-carousel-item v-for="(item, index) in questions" :key="item.id">
					<v-row>
						<v-col cols="12" sm="5" md="6" v-if="item.img">
							<v-img
								:src="item.img"
								class="grey lighten-2"
								aspect-ratio="1.7777777778"
								max-width="960"
								alt=""
							></v-img>
						</v-col>
						<v-col cols="12" :sm="(item.img? '7' : '12')" :md="(item.img? '6' : '12')">
							<div class="pa-3" color="green">
								<h1 class="display-2 headline font-weight-light">{{ item.question }}</h1>
								<v-list>
									<v-list-item v-for="(option, i) in item.options" :key="i">
										<v-list-item-icon>
											<v-icon>mdi-check</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title v-text="option"></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>
								<v-divider color="white"></v-divider>
								<v-radio-group v-model="answers[item.id]" row @change="setNext(index+1, item.id)">
									<v-radio :label="$t('validation.yes')" value="2"></v-radio>
									<v-radio :label="$t('validation.no')" value="1"></v-radio>
								</v-radio-group>
							</div>
						</v-col>
					</v-row>
				</v-carousel-item>
			</v-carousel>
			<v-footer padless color="green" class="mt-auto">
				<v-row justify="space-around" no-gutters class="pa-3 align-center">
					<v-btn @click="setModel(0)" primary fab small color="white" class="green--text" :disabled="prevdisabled">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
					<div class="white--text" v-if="questions.length">{{ parseInt((model+(answered[model+1]==undefined? 0 : parseInt(answered[model+1])))*100/questions.length) }} %</div>
					<v-btn @click="setModel(1)" primary fab small color="white" class="green--text" :disabled="nextdisabled">
						<v-icon>mdi-arrow-right</v-icon>
					</v-btn>
					<v-btn primary fab small color="white" class="green--text" @click="saveValidation" v-if="result_btn"><v-icon>mdi-check</v-icon></v-btn>
				</v-row>
			</v-footer>
		</div>
		<div v-if="saved">
			<h2>{{ $t('validation.thank_you') }}</h2>
			<p>{{ $t('validation.saved') }}</p>
		</div>
		<v-overlay :value="overlay">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	data: () => ({
		uuid: false,
		answers: false,
		result_btn: false,
		answered: [],
		firma: '',
		model: 0,
		questions: [],
		cur_next: false,
		overlay: false,
		saved: false,
	}),
	methods: {
		setModel(q) {
			var i;
			if(q) {
				this.model++;
				for(i=this.model; i<this.questions.length; i++) {
					if(this.questions[i].show) {
						this.model = i;
						break;
					}
				}
			} else {
				this.model--;
				for(i=this.model; i>=0; i--) {
					if(this.questions[i].show) {
						this.model = i;
						break;
					}
				}
			}
			if((this.model+1)==this.questions.length) {
				this.cur_next = false;
				if((this.model+1)==this.questions.length && !(this.answered[(this.model+1)]===undefined || this.answered[(this.model+1)]===0)) {
					this.result_btn = true;
				}
			} else {
				this.cur_next = !(this.answered[(this.model+1)]===undefined || this.answered[(this.model+1)]===0) ;	
			}
		},
		setNext(index, id) {
			if(this.answers[id]==1 || this.answers[id]==2){
				this.answered[index] = 1;
				if((this.model+1)<this.questions.length) {
					this.cur_next = true;
				}
				if((this.model+1)==this.questions.length) {
					this.result_btn = true;
				}
			} else {
				this.answered[index] = 0;
				this.answers[id] = null;
				this.cur_next = false;
				this.result_btn = false;
			}
		},
		saveValidation() {
			this.overlay = true;
			axios.post('/api/saveValidation', {lang:this.$i18n.locale, uuid:this.uuid, answers:this.answers})
			.then(response => {
				if(response.data.action=='ok') {
					this.questions = null;
					this.saved = true;
					this.overlay = false;
				} else {
					this.overlay = false;
				}
				
			})
			.catch(function(error) {
				if (error.response && error.response.status === 401) {
					window.location.href = "/"+this.$i18n.locale;
				}
			});
			setTimeout(() => {
				this.overlay = false
			}, 3000)
		},
	},
	mounted () {
    document.title = this.$t('menu.fragen') +  ' - ' + this.$t('general.page_title');
    this.uuid = this.$route.params.uuid;
		axios.post('/api/getCheck', {lang:this.$i18n.locale, uuid:this.uuid})
		.then(response => {
			if(response.data.action=='ok') {
				this.questions = response.data.answers;
				this.firma = response.data.firma;
				if(!this.answers) {
					let answers = {};
					this.questions.forEach(function(item){
						answers[item.id]=null;
					});
					this.answers = answers;	
				}
			} else if(response.data.action=='saved'){
				this.questions = null;
				this.saved = true;
			} else {
				this.firma = false;
			}
			
		})
		.catch(function(error) {
			if (error.response && error.response.status === 401) {
				window.location.href = "/"+this.$i18n.locale;
			}
		});
	},
	watch: {
	},
	computed: {
		prevdisabled: function() {
			return (this.model<1)
		},
		nextdisabled: function() {
			return !this.cur_next;
		},
	}
};
</script>
<style scoped>
.theme--dark.v-list {
    background: transparent;
}
.theme--dark.v-divider {
    border-color: rgba(255, 255, 255, 0.5);
}
</style>