<template>
  <v-app>
	  <v-navigation-drawer right disable-resize-watcher style="z-index: 1002" :clipped="clipped" v-model="drawer" enable-resize-watcher app dark>
      <v-list>
        <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.path"
            v-show="item.show || logged"
            exact
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app>
      <v-toolbar-items><router-link :to="'/'+$i18n.locale"><v-img :src="require('@/assets/logo.png')" alt="Startseite" width="131" height="48"></v-img></router-link></v-toolbar-items>
      <div class="flex-grow-1"></div>
      <v-btn
          color="primary"
          dark
      >
        {{ currentLanguage }}
      </v-btn>
      <v-btn icon :href="'/'+$i18n.locale+'/login'" title="login">
        <v-icon :color="(logged? 'primary' : '')">mdi-account</v-icon>
      </v-btn>
      <v-btn @click.stop="drawer = !drawer" class="elevation-0 bg-transparent-important px-1" aria-label="$t('general.menu')">
        <span class="mr-1 grey--text text--darken-1 d-none d-sm-inline">{{ $t('general.menu') }}</span>
        <v-icon v-if="drawer" large :color="'grey darken-1'">mdi-close</v-icon>
        <v-icon v-else large :color="'grey darken-1'">mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid fill-height theme--light green white--text>
        <v-layout>
          <v-flex>
            <router-view></router-view>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <v-footer
        color="white"
        padless
        class="flex-column"
    >
      <v-container>
        <v-row
                class="justify-center d-none d-sm-flex pa-4"
        >
          <v-col cols="12" lg="6" class="d-flex flex-column flex-lg-row justify-center align-items-center">
            <span class="align-self-center mr-4 mb-4 body-2 font-weight-light">{{ $t('home.footer') }}</span>
            <img src="/img/SMWA_ESF_LO_Sachsen_2019_QUER_RGB.png" width="500" alt="" style="align-self: center; height: auto">
          </v-col>
          <v-col cols="12" lg="6" class="d-flex flex-column flex-lg-row justify-center align-items-center">
            <span class="align-self-center mr-4 mb-4 body-2 font-weight-light" v-html="$t('home.footer2')"></span>
            <img src="/img/logo-fachkraefte.png" alt="" height="57" style="align-self: center">
          </v-col>
        </v-row>
        <v-row
                no-gutters
                class="d-block text-center d-sm-none pa-4"
        >
          <v-col cols="12">
            <div class="align-self-center text-center mb-4 body-2 font-weight-light">{{ $t('home.footer') }}</div>
            <img src="/img/SMWA_ESF_LO_Sachsen_2019_HOCH_RGB.png" alt="" style="max-width:100%;"/>
          </v-col>
          <v-col cols="12">
            <div class="align-self-center text-center mr-4 my-4 body-2 font-weight-light" v-html="$t('home.footer2')"></div>
            <img src="/img/logo-fachkraefte.png" alt="" height="57">
          </v-col>
        </v-row>
      </v-container>
      <v-row
          no-gutters
          class="primary darken-1 white--text align-self-stretch justify-center d-flex flex-column flex-sm-row"
      >
        <v-btn
            color="white"
            text
            class="my-2"
            :href="'/'+$i18n.locale+'/'+$t('menu.impressum_url')"
        >
          {{ $t('menu.impressum_title') }}
        </v-btn>
        <v-btn
            color="white"
            text
            class="my-2"
            :href="'/'+$i18n.locale+'/'+$t('menu.datenschutz_url')"
        >
          {{ $t('menu.datenschutz_title') }}
        </v-btn>
        <v-btn
            color="white"
            text
            class="my-2"
            :href="'/'+$i18n.locale+'/'+$t('menu.nutzungsvereinbarung_url')"
        >
          {{ $t('menu.nutzungsvereinbarung_title') }}
        </v-btn>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'AppLayout',
  data: () => ({
      drawer: false,
      clipped: false,
      languages: ['DE', 'EN', 'PL', 'CZ'],
      currentLanguage: 'DE',
      items: []
  }),
  props: {
    source: String
  },
  methods: {
    setCurrentLanguage: function(language) {
      if(this.currentLanguage !== language) {
        this.currentLanguage = language;
        this.$i18n.locale = language.toLowerCase();
        this.$router.push({
          name: this.$router.currentRoute.name,
          params: {'lang': this.$i18n.locale}
        });
      }
    }
  },
  computed: {
	...mapState(['logged', 'name']),
  },
  created () {
  },
  updated () {
  },
  components: {
  },
  mounted () {
	var usertype = '/'+localStorage.getItem('usertype');
    if(usertype == '/null') {
      usertype = '/ag';
    }
	this.items = [
		{
			action: 'home',
			title: 'menu.home',
			path: '/'+this.$i18n.locale+'/',
			icon: 'mdi-home',
			show: true,
		},
    {
      action: 'login',
      title: 'Dashboard',
      path: '/'+this.$i18n.locale+'/login',
      icon: 'mdi-view-dashboard',
      show: false,
    },
		{
			action: 'fragen',
			title: 'menu.fragen',
			path: '/'+this.$i18n.locale+'/fragen'+usertype,
			icon: 'mdi-forum',
			show: true,
		},
		{
			action: 'ergebnisse',
			title: 'menu.ergebnisse',
			path: '/'+this.$i18n.locale+'/ergebnisse',
			icon: 'mdi-playlist-check',
			show: false,
		},
		{
			action: 'siegel',
			title: 'menu.siegel',
			path: '/'+this.$i18n.locale+'/siegel',
			icon: 'mdi-seal',
			show: false,
		},
		{
			action: 'profile',
			title: 'menu.profil',
			path: '/'+this.$i18n.locale+'/profile',
			icon: 'mdi-account-details',
			show: false,
		},
	];
  this.currentLanguage = 'DE'; //this.$i18n.locale.toUpperCase();
  this.$i18n.locale = 'de';
  },
};
</script>
<style scoped>
.v-application .green--text {color: #0B7A20 !important;}
</style>
<style lang="scss">
   .v-application--is-ltr .v-banner__actions { margin-left: 0; }
</style>