import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		accessToken:  localStorage.getItem('access_token') ||  '',
		name : localStorage.getItem('name') ||  '',
		logged : localStorage.getItem('logged') ||  '',
		usertype : localStorage.getItem('usertype') ||  '',
		lastCheck: JSON.parse(localStorage.getItem('lastCheck')) ||  '',
		lastAnswers: JSON.parse(localStorage.getItem('lastAnswers')) ||  '',
		lastOptions: JSON.parse(localStorage.getItem('lastOptions')) ||  '',
	},
	mutations: {
		setAccessToken: (state, payload) => {
			state.accessToken = payload;
		},
		setName: (state, payload) => {
			state.name = payload;
		},
		setLogged: (state, payload) => {
			state.logged = payload;
		},
		setUsertype: (state, payload) => {
			state.usertype = payload;
		},
		setLastCheck: (state, payload) => {
			state.lastCheck = payload;
		},
		setLastAnswers: (state, payload) => {
			state.lastAnswers = payload;
		},
		setLastOptions: (state, payload) => {
			state.lastOptions = payload;
		},
	},
	modules: {},
	actions: {
		setAccessToken: async (context, payload) => {
			context.commit('setAccessToken', payload);
		},
		setName: async (context, payload) => {
			context.commit('setName', payload);
		},
		setLogged: async (context, payload) => {
			context.commit('setLogged', payload);
		},
		setUsertype: async (context, payload) => {
			context.commit('setUsertype', payload);
		},
		setLastCheck: async (context, payload) => {
			context.commit('setLastCheck', payload);
		},
		setLastAnswers: async (context, payload) => {
			context.commit('setLastAnswers', payload);
		},
		setLastOptions: async (context, payload) => {
			context.commit('setLastOptions', payload);
		}
	},
	getters: {
		logged: state => state.logged,
	},
})
