<template>
	<div>
		<h1 class="display-2 font-weight-thin mb-3" v-html="$t('profile.header')"></h1>
		<v-form v-model="valid" ref="form" @submit="submit">
      <v-card class="mb-3">
        <v-alert type="error" outlined v-if="error" v-text="$t('general.error')"></v-alert>
        <v-alert type="success" outlined v-if="success_msg"  v-text="success_msg"></v-alert>
      </v-card>
      <v-card class="mb-3">
				<v-card-title><h2 class="headline">{{ $t('register.header2') }}</h2></v-card-title>
				<v-card-text>
					<v-text-field
						:label="$t('register.label_company') + ' *'"
						v-model="profile.description"
            :error-messages="errors_msg.description"
            required
					></v-text-field>
					<v-textarea
						:label="$t('register.label_description')"
						v-model="profile.company_description"
						counter="500"
						:rules="[v => (v || '' ).length <= 500 || $t('register.textareaRule', {length: 500})]"
            :error-messages="errors_msg.company_description"
						auto-grow
					></v-textarea>
          <v-text-field
              :label="$t('register.label_web')"
              v-model="profile.company_web"
              :error-messages="errors_msg.company_web"
          ></v-text-field>
          <v-img
            :alt="profile.description + ' Logo'"
            :src="profile.logo_url"
            max-width="300px"
          ></v-img>
          <v-file-input
              accept="image/*"
              v-model="profile.logo"
              :label="$t('register.label_logo')"
          ></v-file-input>
          <v-checkbox
              :label="$t('register.label_company_has_profile_page')"
              v-model="profile.company_has_profile_page"
          ></v-checkbox>
          <div>
            <p>{{$t('register.profillink')}} <a :href="profile.link" v-text="profile.link"></a></p>
          </div>
          <v-checkbox
              :label="'Ich möchte Werbematerial per E-Mail, Telefon und Post erhalten'"
              v-model="profile.advertising_consent"
          ></v-checkbox>
          <div class="caption my-3">* {{ $t('register.require') }}</div>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title><h2 class="headline">{{ $t('register.header2a') }}</h2></v-card-title>
        <v-card-text>
          <v-text-field
              :label="$t('register.label_company_address_street') + (profile.company_has_profile_page ? ' *' : '')"
              v-model="profile.company_address_street"
              :error-messages="errors_msg.company_address_street"
              :required="profile.company_has_profile_page"
          ></v-text-field>
          <v-text-field
              :label="$t('register.label_company_address_number') + (profile.company_has_profile_page ? ' *' : '')"
              v-model="profile.company_address_number"
              :error-messages="errors_msg.company_address_number"
              :required="profile.company_has_profile_page"
          ></v-text-field>
          <v-text-field
              :label="$t('register.label_company_address_zip') + (profile.company_has_profile_page ? ' *' : '')"
              v-model="profile.company_address_zip"
              :error-messages="errors_msg.company_address_zip"
              :required="profile.company_has_profile_page"
          ></v-text-field>
          <v-text-field
              :label="$t('register.label_company_address_city') + (profile.company_has_profile_page ? ' *' : '')"
              v-model="profile.company_address_city"
              :error-messages="errors_msg.company_address_city"
              :required="profile.company_has_profile_page"
          ></v-text-field>
          <div class="caption my-3" v-if="profile.company_has_profile_page">* {{ $t('register.require') }}</div>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title><h2 class="headline">{{ $t('register.header2b') }}</h2></v-card-title>
        <v-card-text>
          <v-select v-model="profile.companysize"
                    :items="companySizes"
                    item-text="title"
                    item-value="id"
                    :label="$t('fragen.label_companySize')"
                    :error-messages="errors_msg.companysize"
          ></v-select>
          <v-select v-model="profile.branche"
                    :items="branches"
                    item-text="title"
                    item-value="id"
                    :label="$t('fragen.label_branche')"
                    :error-messages="errors_msg.branche"
          ></v-select>
          <v-select v-model="profile.location"
                    :items="locations"
                    item-text="title"
                    item-value="id"
                    :label="$t('fragen.label_location')"
                    :error-messages="errors_msg.branche"
          ></v-select>
          <v-autocomplete
              v-model="profile.zip"
              :label="$t('fragen.label_zip')"
              :items="ziplist"
              item-text="title"
              item-value="id"
              :error-messages="errors_msg.zip"
          ></v-autocomplete>
        </v-card-text>
      </v-card>
			<v-card class="mb-3">
				<v-card-title><h2 class="headline">{{ $t('register.header3') }}</h2></v-card-title>
				<v-card-text>
					<v-text-field
						:label="$t('register.label_firstname')"
						v-model="profile.firstname"
            :error-messages="errors_msg.firstname"
					></v-text-field>
					<v-text-field
						:label="$t('register.label_lastname')"
						v-model="profile.lastname"
            :error-messages="errors_msg.lastname"
					></v-text-field>
					<v-text-field
						:label="$t('register.label_contact_email')"
						v-model="profile.contact_email"
            :error-messages="errors_msg.contact_email"
						type="email"
					></v-text-field>
					<v-text-field
						:label="$t('register.label_contact_telefon')"
						v-model="profile.contact_telefon"
            :error-messages="errors_msg.contact_telefon"
					></v-text-field>
				</v-card-text>
			</v-card>
			<v-card class="mb-3">
				<v-card-title><h2 class="headline">{{ $t('profile.header1') }}</h2></v-card-title>
				<v-card-text>
					<v-text-field
						:label="$t('profile.label_old_password')+' *'"
						v-model="old_password"
						type="password"
            autocomplete="new-password"
					></v-text-field>
					<v-text-field
						:label="$t('profile.label_new_password')+' *'"
						v-model="new_password"
						type="password"
						:rules="[newPasswordRules]"
					></v-text-field>
					<v-text-field
						:label="$t('profile.label_new_password1')+' *'"
						v-model="new_password1"
						type="password"
						:rules="[confirmPasswordRules]"
					></v-text-field>
					<div class="caption my-3">* {{ $t('register.require') }}</div>
				</v-card-text>
			</v-card>
			<v-card class="mb-3">
				<v-card-text>
					<v-layout justify-space-between>
						<v-btn @click="save" class="primary mt-3">{{ $t('general.save') }}</v-btn>
					</v-layout>
				</v-card-text>
			</v-card>
		</v-form>
	</div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex'

export default {
	components: {
	},
	data() {
		return {
			showForm: true,
			success_msg: '',
      errors_msg: [],
			error: false,
			valid: false,
			old_password: '',
			new_password: '',
			new_password1: '',
			newPasswordRules1: [],
			emailRules: [],
      profile: {
        company_description: '',
        description: '',
        company_web: '',
        contact_email: '',
        contact_telefon: '',
        company: '',
        logo: [],
        logo_url: '',
        company_has_profile_page: 0,
        firstname: '',
        lastname: '',
        company_address_street: '',
        company_address_number: '',
        company_address_zip: '',
        company_address_city: '',
        companysize: '',
        branche: '',
        location: '',
        zip: '',
        advertising_consent: 0,
        link: ''
			},
      companySizes: [],
      branches: [],
      locations: [],
      ziplist: [],
		}
	},
	methods: {
		...mapActions(['setLogged', 'setAccessToken', 'setName']),
    submit: function(event) {
		  event.stopPropagation();
		  event.preventDefault();
		  this.save();
    },
		save: function () {
			if (this.$refs.form.validate()) {

        const data = {
            old_password: this.old_password,
            new_password: this.new_password,
            company_description: this.profile.company_description,
            firstname: this.profile.firstname,
            lastname: this.profile.lastname,
            description: this.profile.description,
            company_web: this.profile.company_web,
            contact_email: this.profile.contact_email,
            contact_telefon: this.profile.contact_telefon,
            company_has_profile_page: 1 * this.profile.company_has_profile_page,
            company_address_street: this.profile.company_address_street,
            company_address_number: this.profile.company_address_number,
            company_address_zip: this.profile.company_address_zip,
            company_address_city: this.profile.company_address_city,
            companysize: this.profile.companysize,
            branche: this.profile.branche,
            location: this.profile.location,
            zip: this.profile.zip,
            advertising_consent: this.profile.advertising_consent  > 0 ? 1 : 0,
            lang: this.$i18n.locale,
        };

        let formData = new FormData();
        for(let property in data) {
          formData.append(property, data[property]);
        }

        if (this.profile.logo) {
          formData.append("logo", this.profile.logo);
        }

				axios.post('/api/saveProfile', formData)
				.then(res=>{
					if(res.data.msg!== undefined) {
						this.error = false;
						this.success_msg = res.data.msg;
            this.errors_msg = false;
						this.showForm = false;
					} else {
						this.error = true;
            this.success_msg = false;
						this.errors_msg = res.data.errors_msg;
					}
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
				})
				.catch(function() {
					this.error = true;
				});
			}
		},
	},
	mounted() {
		if(!this.logged) {
			this.$router.push('/'+this.$i18n.locale+'/login')
		}
    document.title = 'Profil - ' + this.$t('general.page_title');
		this.newPasswordRules1 = [
			(v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(v) || this.$t('register.passwordRules2')
		];
		this.emailRules = [
			(v) => !!v || this.$t('register.emailRules1'),
			(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('register.emailRules2')
		];
    axios.post('/api/getProfile')
        .then(response => {
          this.profile      = response.data.profile;
          this.companySizes = response.data.companysizes;
          this.branches     = response.data.branches;
          this.locations    = response.data.locations;
          this.ziplist      = response.data.ziplist;
        })
        .catch(function(error) {
          if (error.response && error.response.status === 401) {
            window.location.href = "/"+this.$i18n.locale+"/login";
          }
        });
	},
	computed: {
		...mapState(['logged', 'name']),
		newPasswordRules() {
			return (this.new_password? /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(this.new_password) : true) || this.$t('register.passwordRules2');
		},
		confirmPasswordRules() {
			return this.new_password1 === this.new_password || this.$t('profile.confirmPasswordRule');
		}
	},
};
</script>
