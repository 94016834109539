<template>
	<div>
		<div v-if="showForm">
			<v-form v-model="valid" ref="form" @submit="handleSubmit">
				<v-card class="mb-3">
					<v-card-title><div class="headline">{{ $t('register.header') }}</div></v-card-title>
					<v-card-text>
						<v-alert type="error" outlined v-if="error">{{ $t('general.error') }}</v-alert>
            <v-text-field
                :label="$t('register.label_company') + ' *'"
                v-model="description"
                :rules="companyRules"
                :error-messages="errors_msg.description"
                required
            ></v-text-field>
            <v-text-field
							:label="$t('register.label_username') + ' *'"
							v-model="username"
							:rules="usernameRules"
							:error-messages="errors_msg.username"
              persistent-hint
              :messages="['mind. 6 Zeichen; erlaubte Zeichen: Buchstaben (a - z, A - Z), Ziffern (0 - 9) sowie Sonderzeichen (!, ?, -, +, &, (, ), .)']"
							required
						></v-text-field>
						<v-text-field
							:label="$t('register.label_password') + ' *'"
							v-model="password"
							type="password"
							:rules="passwordRules"
              :error-messages="errors_msg.password"
							required
						></v-text-field>
						<v-text-field
							:label="$t('register.label_email') + ' *'"
							v-model="email"
							type="email"
							:rules="emailRules"
              :error-messages="errors_msg.email"
							required
						></v-text-field>
            <v-checkbox
              v-model="privacy"
              :rules="privacyRules"
              :error-messages="errors_msg.privacy"
              required
            >
              <template v-slot:label>
                {{ $t('register.privacy_policy1') }}
                <a :href="'/'+$i18n.locale+'/datenschutz'" @click.stop target="_blank">{{ $t('register.privacy_policy2') }}</a>
                {{ $t('register.privacy_policy3') }}
                <a :href="'/'+$i18n.locale+'/nutzungsvereinbarung'" @click.stop target="_blank">{{ $t('register.privacy_policy4') }}</a>
                {{ $t('register.privacy_policy5') }}
              </template>
            </v-checkbox>
            <v-checkbox
                :label="$t('register.advertising_consent')"
                v-model="consent"
            ></v-checkbox>
						<div class="caption my-3">* {{ $t('register.require') }}</div>
						<v-layout justify-space-between>
							<v-btn type="submit" class="primary mt-3">{{ $t('register.register') }}</v-btn>
						</v-layout>
					</v-card-text>
				</v-card>
			</v-form>
		</div>
		<div v-else>
			<v-card>
				<v-card-title><div class="headline">{{ $t('register.header') }}</div></v-card-title>
				<v-card-text>
					<v-alert dense text type="success">{{ success_msg }}</v-alert>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import axios from 'axios';
import { mapState, mapActions } from 'vuex'

export default {
	components: {
	},
	data() {
		return {
			showForm: true,
			success_msg: '',
			error: false,
			errors_msg: [],
			valid: false,
      description: '',
      companyRules: [],
      username: '',
      usernameRules: [],
      password: '',
      passwordRules: [],
			email: '',
			emailRules: [],
      privacy: false,
      privacyRules: [],
      consent: false
		}
	},
	methods: {
		...mapActions(['setLogged', 'setAccessToken', 'setName']),
    handleSubmit: function(event) {
		  event.stopPropagation();
		  event.preventDefault();
		  this.register();
    },
		register: function () {
      const lastCheck = localStorage.getItem('lastCheck');
      const lastAnswers = localStorage.getItem('lastAnswers');
      const lastOptions = localStorage.getItem('lastOptions');

      let results = '';
      let answers = '';
      let branche = '';
      let companySize = '';
      let location = '';
      let zip = '';
      let percentage = '';
      let shift = '';
      let contract = '';
      let type = '';
      let saveCheck = false;

      if (lastCheck !== null) {
        results = JSON.parse(lastCheck);
        saveCheck = true;
      }
      
      if (lastAnswers !== null) {
        answers = JSON.parse(lastAnswers);
      }
      
      if (lastOptions !== null) {
        let values = JSON.parse(lastOptions);
        branche = values.branche;
        companySize = values.companySize;
        location = values.location;
        zip = values.zip;
        percentage = values.percentage;
        shift = values.shift;
        contract = values.contract;
        type = values.type;        
      }
      
			if (this.$refs.form.validate()) {
				axios.post('/api/register', {
          description: this.description,
          username: this.username,
          password: this.password,
          email: this.email,
					lang:this.$i18n.locale,
          advertising_consent: this.consent ? 1 : 0,
          //If there is an unsaved check, let's save it now
          results: results,
          answers: answers,
          branche: branche,
          companySize: companySize,
          location: location,
          zip: zip,
          percentage: percentage,
          shift: shift,
          contract: contract,
          type: type,
          saveCheck: saveCheck
				})
				.then(res=>{
					if(res.data.msg!== undefined) {
						this.error = false;
						this.success_msg = res.data.msg;
						this.showForm = false;
            localStorage.removeItem('lastCheck');
            localStorage.removeItem('lastAnswers');
            localStorage.removeItem('lastOptions');
            localStorage.setItem('showSave', 'false');
					} else {
						this.error = true;
						this.errors_msg = res.data.error;
					}
				})
				.catch(function() {
					this.error = true;
				});
			}
		},
	},
	mounted() {
		if(this.logged) {
			this.$router.push('/'+this.$i18n.locale+'/login')
		}
    document.title = 'Registrieren - ' + this.$t('general.page_title');
		this.privacyRules = [
      (v) => !!v || this.$t('register.privacyRules1')
    ];
		this.companyRules = [
      (v) => !!v || this.$t('register.companyRules1')
    ];
		this.passwordRules = [
			(v) => !!v || this.$t('register.passwordRules1'),
			(v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(v) || this.$t('register.passwordRules2')
		];
		this.usernameRules = [
			(v) => !!v || this.$t('register.usernameRules1'),
			(v) => v.length >= 6 || this.$t('register.usernameRules1')
		];
		this.emailRules = [
			(v) => !!v || this.$t('register.emailRules1'),
			(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('register.emailRules2')
		];
	},
	computed: {
		...mapState(['logged', 'name'])
	},
};
</script>
<style scoped>
/deep/ .v-input--checkbox .v-label {
  display: block;
}
</style>