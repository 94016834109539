<template>
	<div>
		<h1 class="display-2 font-weight-thin mb-6">Siegel und Validierung</h1>
		<h2 class="d-sr-only font-weight-thin mb-6">Aktueller Status</h2>
		<div v-if="siegel">
        <template v-if="siegel.ja && siegel.gold!='0' && siegel.gold!='1'">
          <v-row no-gutters>
            <v-col cols="12" sm="3" class="flex-grow-0 flex-shrink-0 mb-6 text-center px-1">
              <v-img :src="siegel.src" alt="" width="200" class="mb-1 ml-auto mr-auto"></v-img>
              Gültig bis: {{ siegel.gold }}
            </v-col>

            <v-col cols="12" sm="9" class="flex-grow-1 flex-shrink-0 mb-3">
              <v-card class="mb-3 px-3 py-3">
                <p>
                  Herzlichen Glückwunsch zum erfolgreichen Audit zum familienfreundlichen Unternehmen.
                  Neben dem erhaltenen Zertifikat sind Sie nun dazu berechtig für drei Jahre das Gold-Siegel
                  für Ihre interne und externe Unternehmenskommunikation zu verwenden.
                </p>
              </v-card>
            </v-col>
          </v-row>
            <v-card class="pa-3">
              <h2 class="mb-3">Mein Siegel</h2>
              <div class="font-weight-medium">Präsentieren Sie Ihre Auszeichnung auf Ihrer Website, Karriereseiten, Stellenanzeigen uvm.:</div>
              <div class="my-3">
                für Druck-Medien:
              </div>
              <v-btn @click="downloadSiegel" class="mb-6">Download Siegel als PNG</v-btn>

              <div class="mb-3">
                für Online-Medien:
              </div>
              <pre class="mb-3"><code class="pa-3 d-block" ref="siegelcode">{{ siegel.code }}</code></pre>
              <v-btn @click="copyCode">HTML-Code kopieren</v-btn>
            </v-card>
        </template>
        <template v-else-if="siegel.ja && siegel.silber!='0' && siegel.silber!='1'">
          <v-row no-gutters>
            <v-col cols="12" sm="3" class="flex-grow-0 flex-shrink-0 mb-6 text-center px-1">
              <v-img :src="siegel.src" alt="" width="200" class="mb-1 mr-auto ml-auto"></v-img>
              Gültig bis: {{ siegel.silber }}
            </v-col>
            <v-col cols="12" sm="9" class="flex-grow-1 flex-shrink-0">
              <v-card class="pa-3">
                <h2 class="display-1 font-weight-thin mb-6">Nächste Schritte</h2>
                <div class="mb-3">
                  <p>
                    Herzlichen Glückwunsch! Ihre Mitarbeitenden haben Ihre Angaben zur Familienfreundlichkeit für den Erhalt
                    des Silber-Siegels validiert. Das Siegel hat eine Gültigkeit von einem Jahr und kann sowohl für Ihre interne
                    als auch externe Unternehmenskommunikation verwendet werden. Zur Verlängerung der Gültigkeitsdauer können Sie
                    einen neuen Check mit erneuter Validierung starten. Verbessert sich Ihre Familienfreundlichkeit durch
                    die Umsetzung von empfohlenen Maßnahmen, können Sie dies durch einen neuen Check bestätigen.
                    Doch beachten Sie, jeder neue Check aktualisiert Ihren Status der Familienfreundlichkeit und
                    die Berechtigung zur Darstellung des Siegels - unabhängig davon, ob die Veränderung positiv oder negativ war.
                    Alle Änderungen müssen zudem erneut durch Ihre Mitarbeitenden validiert werden.
                  </p>
                  <p>
                    Ein Gold-Siegel* erhalten Sie mit Zertifikat nach einem Audit von unseren Experten vor Ort.
                    Kontaktieren Sie dafür gern unser Team für die Beratung zur Familienfreundlichkeit in Ihrem Unternehmen.
                    Das Gold-Siegel und Zertifikat "Familienfreundliches Unternehmen" haben eine Gültigkeitsdauer von drei Jahren.
                  </p>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-expansion-panels hover multiple>
            <v-expansion-panel class="mt-3 rounded">
              <v-expansion-panel-header class="headline font-weight-thin text--primary">Validierung für Gold</v-expansion-panel-header>
              <v-expansion-panel-content class="px-6 pb-3">
                <div v-if="siegel.gold=='1'" class="title my-3">Validierung läuft</div>
                <div v-else>
                  <p>Kontaktieren Sie unser Experten-Team für die persönliche Begutachtung der Familienfreundlichkeit in Ihrem Unternehmen. Wir beraten Sie zudem individuell zur Verbesserung der Familienfreundlichkeit und damit Ihrer Potentiale zur Mitarbeitersicherung und -gewinnung.</p>
                  <p>Telefon: <a href="tel:+493514250294">+49 351 42502-94</a>, E-Mail: <a href="mailto:julius.kunath@bsw-mail.de">julius.kunath@bsw-mail.de</a></p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card class="pa-3 mt-3">
            <h2 class="mb-3">Mein Siegel</h2>
            <div class="font-weight-medium">Präsentieren Sie Ihre Auszeichnung auf Ihrer Website, Karriereseiten, Stellenanzeigen uvm.:</div>
            <div class="my-3">
              für Druck-Medien:
            </div>
            <v-btn @click="downloadSiegel" class="mb-6">Download Siegel als PNG</v-btn>

            <div class="mb-3">
              für Online-Medien:
            </div>
            <pre class="mb-3" style="max-width: 800px"><code class="pa-3 d-block" style="border: 1px solid #eaeaea" ref="siegelcode">{{ siegel.code }}</code></pre>
            <v-btn @click="copyCode">HTML-Code kopieren</v-btn>
          </v-card>
        </template>
        <template v-else-if="siegel.ja && siegel.bronze!='0' && siegel.bronze!='1'">
          <v-row no-gutters>
            <v-col cols="12" sm="3" class="flex-grow-0 flex-shrink-0 mb-6 text-center px-1">
              <v-img :src="siegel.src" alt="" width="200" class="mb-1 ml-auto mr-auto"></v-img>
              Gültig bis: {{ siegel.bronze }}
            </v-col>
            <v-col cols="12" sm="9" class="flex-grow-1 flex-shrink-0 mb-3">
              <v-card class="pa-3">
                <h2 class="display-1 font-weight-thin mb-6">Nächste Schritte</h2>
                <p>
                  Herzlichen Glückwunsch! Ihr Check-Ergebnis wurde positiv bewertet!
                  Für den Erhalt des Silber-Siegels muss Ihr Ergebnis von mindestens fünf volljährigen Mitarbeitern validiert werden.
                  Die Validität erhöht sich bei einer steigenden Anzahl von Mitarbeitern, die am Check teilnehmen.
                  Nutzen Sie dafür unser automatisiertes, freiwilliges und anonymes Validierungsverfahren.
                  Bitte beachten Sie: während eine Validierung läuft, kann kein neuer Check gestartet werden.
                  Bitte informieren Sie Ihre betreffenden Arbeitnehmer, dass ihre Daten zur Datenverarbeitung verwendet werden.
                  Es werden keine personenbezogenen Fragen gestellt. Sofern Ihr Unternehmen über einen Betriebsrat verfügt,
                  hat dieser das Recht das Gesamtergebnis einzusehen. Ebenso wie das Bronze-Siegel ist das Silber-Siegel für Sie komplett kostenfrei
                  und hat eine Gültigkeit von einem Jahr. Sie können zur Verlängerung der Frist einen neuen Check starten.
                  Auch nach der Umsetzung unserer empfohlenen Maßnahmen können Sie die verbesserte Familienfreundlichkeit jederzeit
                  durch einen neuen Check bestätigen. Jeder neue Check aktualisiert Ihr Ergebnis/Ihren Status der Familienfreundlichkeit
                  und auch die Berechtigung zur Darstellung des Siegels - unabhängig davon, ob die Veränderung positiv oder negativ war.
                </p>
                <p>
                  Ein Gold-Siegel* erhalten Sie mit Zertifikat nach einem Audit von unseren Experten vor Ort.
                  Kontaktieren Sie dafür gern unser Team für die Beratung zur Familienfreundlichkeit in Ihrem Unternehmen.
                  Das Gold-Siegel und Zertifikat "Familienfreundliches Unternehmen" haben eine Gültigkeitsdauer von drei Jahren.
                </p>
              </v-card>
            </v-col>
          </v-row>
          <v-expansion-panels hover multiple>
            <v-expansion-panel class="rounded">
              <v-expansion-panel-header class="headline font-weight-thin text--primary">Validierung für Silber</v-expansion-panel-header>
              <v-expansion-panel-content class="px-6 pb-3">
                <div v-if="siegel.silber=='1'" class="title my-3">Validierung läuft...</div>
                <div>
                  <v-row v-if="confirmations.length>5">
                    <v-col sm="6" md="4" lg="3">
                      <v-alert dense text type="error" v-if="showErrorMessage">Ungültiger Dateityp</v-alert>
                      <v-file-input
                        show-size
                        truncate-length="15"
                        @change="validFileMimeType"
                        placeholder="CSV-Datei mit E-Mail-Adressen"
                        prepend-icon="mdi-file-table-outline"
                        hint="Datei-Struktur: &quot;E-Mail-Adresse&quot;;&quot;Vorname&quot;;&quot;Nachname&quot;"
                        persistent-hint
                        accept="text/csv, text/x-csv, application/vnd.ms-excel, text/plain"
                      ></v-file-input>
                    </v-col>
                    <v-col sm="6" md="4" lg="3">
                      <v-btn
                        elevation="2"
                        @click.prevent="load"
                        small
                        class="mt-5"
                        v-if="!showErrorMessage&&fileSelected"
                      >Importieren</v-btn>
                    </v-col>
                  </v-row>
                  <v-form ref="form" v-model="valid">
                    <div v-for="(item, index) in confirmations" :key="item.id">
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="item.email"
                          :rules="emailRules"
                          :label="(index+1)+'. E-Mail'"
                          required
                          dense
                          :error="item.error? true : false"
                          :disabled="item.confirmed? true : false"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="item.firstname"
                          label="Vorname"
                          dense
                          :disabled="item.confirmed? true : false"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="item.lastname"
                          label="Nachname"
                          dense
                          :disabled="item.confirmed? true : false"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <div v-if="item.confirmed" class="green--text">validiert am {{ item.confirmed }}</div>
                        <div v-else-if="item.sent">gesendet am {{ item.sent }}</div>
                      </v-col>
                    </v-row>
                    <v-divider class="my-6 d-md-none"></v-divider>
                    </div>
                    <v-btn
                      color="success"
                      class="mr-4 yellow-btn"
                      @click="sendMails"
                    >
                      Senden
                    </v-btn>
                  </v-form>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="mt-3 rounded">
              <v-expansion-panel-header class="headline font-weight-thin text--primary">Validierung für Gold</v-expansion-panel-header>
              <v-expansion-panel-content class="px-6 pb-3">
                <div v-if="siegel.gold=='1'" class="title my-3">Validierung läuft</div>
                <div v-else>
                  <p>Kontaktieren Sie unser Experten-Team für die persönliche Begutachtung der Familienfreundlichkeit in Ihrem Unternehmen. Wir beraten Sie zudem individuell zur Verbesserung der Familienfreundlichkeit und damit Ihrer Potentiale zur Mitarbeitersicherung und -gewinnung.</p>
                  <p>Telefon: <a href="tel:+493514250294">+49 351 42502-94</a>, E-Mail: <a href="mailto:julius.kunath@bsw-mail.de">julius.kunath@bsw-mail.de</a></p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card class="pa-3 mt-3">
            <h2 class="mb-3">Mein Siegel</h2>
            <div class="font-weight-medium">Präsentieren Sie Ihre Auszeichnung auf Ihrer Website, Karriereseiten, Stellenanzeigen uvm.:</div>
            <div class="my-3">
              für Druck-Medien:
            </div>
            <v-btn @click="downloadSiegel" class="mb-6">Download Siegel als PNG</v-btn>

            <div class="mb-3">
              für Online-Medien:
            </div>
            <pre class="mb-3" style="max-width: 800px"><code class="pa-3 d-block" style="border: 1px solid #eaeaea" ref="siegelcode">{{ siegel.code }}</code></pre>
            <v-btn @click="copyCode">HTML-Code kopieren</v-btn>
          </v-card>
        </template>
        <template v-else>
				Sie haben noch kein Siegel.
			</template>
		</div>
		<v-overlay :value="overlay">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import { get } from 'lodash';
import axios from 'axios';
import { mapState } from 'vuex'
import Papa from 'papaparse';
import mimeTypes from "mime-types";

export default {
	data: () => ({
		siegel: null,
		valid: true,
		confirmations: null,
		emailRules: [],
		errors: null,
		overlay: false,
		csv: null,
        isValidFileMimeType: false,
        fileSelected: false,
        chosenFile: null,
	}),
	props: {
		validation: {
            type: Boolean,
            default: true,
        },
        fileMimeTypes: {
            type: Array,
            default: () => {
                return ["text/csv", "text/x-csv", "application/vnd.ms-excel", "text/plain"];
            }
        },
	},
	methods: {
		copyCode: function () {
			navigator.clipboard.writeText(this.siegel.code);
		},
		validate: function () {
			this.$refs.form.validate()
		},
		sendMails: function () {
			this.overlay = true;
			axios.post('/api/sendMails', {
				confirmations: this.confirmations,
			})
			.then(response => {
        this.confirmations = response.data.confirmations;
				this.overlay = false;
			})
			.catch(function(error) {
				if (error.response && error.response.status === 401) {
					window.location.href = '/'+this.$i18n.locale+'/login';
				//} else {
					//this.alertMsg = this.$t('general.error');
					//this.alertType = 'error';
					//this.showAlert = true;
				}
			});
		},
		downloadSiegel: function () {
			axios({
				url: this.siegel.img,
				method: 'GET',
				responseType: 'blob', // important
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'siegel.png');
				document.body.appendChild(link);
				link.click();
			});
		},
        validFileMimeType(file) {
            if (file) {
            	const mimeType = file.type === "" ? mimeTypes.lookup(file.name) : file.type;
                this.fileSelected = true;
                this.isValidFileMimeType = this.validation ? this.validateMimeType(mimeType) : true;
                this.chosenFile = file;
            } else {
                this.isValidFileMimeType = !this.validation;
                this.fileSelected = false;
            }
        },
        validateMimeType(type) {
            return this.fileMimeTypes.indexOf(type) > -1;
        },
        load() {
            const _this = this;
            this.readFile((output) => {
                _this.csv = get(Papa.parse(output, { skipEmptyLines: true }), "data");
                var i = 0;
                var rows = _this.confirmations.length;
                _this.csv.forEach(function(item){
                	if(i<rows) {
	                	try {
	                		if(!_this.confirmations[i].confirmed) {
	                			_this.confirmations[i].email = item[0];
		                    	_this.confirmations[i].firstname = item[1];
		                    	_this.confirmations[i].lastname = item[2];	
	                		}
	                	} catch (err) {
	                		console.log(err)
	                	}
                	} else {
                		return false;
                	}
                	i++;
				});
            });
        },
        readFile(callback) {
            let file = this.chosenFile;
            if (file) {
                let reader = new FileReader();
                reader.readAsText(file, "UTF-8");
                reader.onload = function (evt) {
                    callback(evt.target.result);
                };
                reader.onerror = function () {
                };
            }
        },
	},
	mounted () {
		if(!this.logged) {
			this.$router.push('/'+this.$i18n.locale+'/login')
		}
    document.title = 'Siegel' + ' - ' + this.$t('general.page_title');
		this.emailRules = [
			(v) => !!v || this.$t('register.emailRules1'),
			(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('register.emailRules2')
		];
		axios.post('/api/getSiegel', {lang:this.$i18n.locale})
		.then(response => {
			this.siegel = response.data.siegel;
			this.confirmations = response.data.confirmations;
		})
		.catch(function(error) {
			if (error.response && error.response.status === 401) {
				window.location.href = "/"+this.$i18n.locale+"/login";
			}
		});
	},
	watch: {
	},
	computed: {
		...mapState(['logged']),
        showErrorMessage() {
            return this.fileSelected && !this.isValidFileMimeType;
        },
	}
};
</script>
<style scoped>
	.one-line {
		white-space: nowrap;
	}
	pre {
		white-space: pre-wrap;
	}

  code {
    background: #FAFAFA;
    color: #1B5E20;
  }
</style>