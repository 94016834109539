<template>
  <v-app>
    <component :is="layout"></component>
  </v-app>
</template>

<script>
const default_layout = "home";

export default {
  name: 'App',
  computed: {
	layout () {
		return (this.$route.meta.layout || default_layout) + "-layout";
    }
  },
  created () {
  },
  updated () {
  },
  components: {
  },
  mounted () {
  },
};
</script>
<style lang="scss">
.v-label.theme--dark { color: #ffffff !important;}
.theme--light.v-list-item .v-list-item__mask {
    color: #218233 !important;
    background: transparent !important;
}
</style>