<template>
	<div>
		<div v-if="firma" class="my-5">
      <v-row>
        <v-col>
          <h1 class="display-2 font-weight-thin mb-6">Profil von {{firma.title}}</h1>
        </v-col>
      </v-row>
      <v-card class="pa-3 mb-3">
        <v-row>
          <v-col v-if="firma.logo">
            <v-img :src="firma.logo" :alt="firma.title" max-width="300"></v-img>
          </v-col>
          <v-col>
              <div class="mb-3">
                <h2>Anschrift</h2>
                {{ firma.title }}<br>
                {{ firma.street }} {{ firma.number }}<br>
                {{ firma.zip }} {{ firma.city }}<br>
                <a :href="firma.company_web" rel="noopener noreferrer">{{ firma.company_web }}</a>
              </div>
              <div>
                {{ firma.description }}
              </div>
          </v-col>
        </v-row>
      </v-card>
      <v-row v-if="firma.validation.date">
        <v-col cols="12" sm="3" class="flex-grow-0 flex-shrink-0 mb-3 text-center px-1" v-if="firma.siegel">
          <v-img :src="firma.siegel.src" alt="" width="200" class="mb-1 ml-auto mr-auto"></v-img>
          Gültig bis: {{ firma.siegel.bis }}
        </v-col>
        <v-col cols="12" sm="9">
          <v-card class="pa-3">
            <h2>Maßnahmen für Familienfreundlichkeit</h2>
            <div v-for="theme in firma.validation.themes"  :key="theme.title">
              <h4>{{ theme.title }}</h4>
              <ul v-for="option in theme.done" :key="option">
                <li>{{ option.hasOwnProperty('title') ? option.title : option }}</li>
              </ul>
            </div>
          </v-card>
        </v-col>
      </v-row>
		</div>
		<div v-if="unauthorized">
      <h1 class="display-2 font-weight-thin mb-6">Profil nicht freigeschaltet</h1>
      <p>Dieses Profil wurde von Unternehmen nicht freigeschaltet.</p>
    </div>
    <div v-if="error">
			<h1 class="display-2 font-weight-thin mb-6">Fehler 404</h1>
			<p>Es tut uns leid. Die von Ihnen angeforderte Seite wurde leider nicht gefunden.</p>
		</div>
		<v-overlay :value="overlay">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	data: () => ({
		firma: null,
    unauthorized: false,
		error: false,
		overlay: true,
	}),
	methods: {
	},
	mounted () {
		this.uuid = this.$route.params.uuid;
		axios.post('/api/getUnternehmen', {lang:this.$i18n.locale, uuid:this.uuid})
		.then(response => {
			if(response.data.action == 'ok') {
				this.firma = response.data.firma;
        document.title = 'Profil von ' + this.firma.title +  ' - ' + this.$t('general.page_title');
			} else if (response.data.action == 'unauthorized') {
			  this.unauthorized = true;
      } else {
				this.error = true;
			}
			this.overlay = false;
		})
		.catch(function() {
			this.error = true;
			this.overlay = false;
		});
	},
	watch: {
	},
	computed: {
	}
};
</script>
<style scoped>
	.one-line {
		white-space: nowrap;
	}
</style>