<template>
	<div>
		<v-card>
			<v-card-title><div class="headline">{{ $t('resetPassword.header') }}</div></v-card-title>
			<v-card-text class="pt-4">
				<div v-if="message" v-html="message"></div>
				<div v-else>
					<v-form @submit="send" v-model="valid" ref="form">
						<v-alert type="error" outlined v-if="error_msg">
              {{ error_msg }}
              <span v-if="verify_btn">
								{{ $t('login.verify_text') }}
								<br>
								<v-btn class="mt-3 verify-button" outlined light color="red" @click="sendVerifyMail">{{ $t('login.send') }}</v-btn>
							</span>
						</v-alert>
						<v-text-field
							:label="$t('resetPassword.label_email')"
							v-model="email"
							:rules="emailRules"
							required
						></v-text-field>
						<v-layout>
							<v-btn class="primary mt-3" type="submit">{{ $t('resetPassword.send') }}</v-btn>
						</v-layout>
					</v-form>
				</div>
			</v-card-text>
			<v-divider class="mx-4"></v-divider>
			<v-card-text class="pt-4">
				<v-btn text small :href="'/'+$i18n.locale+'/login'" color="green" class="mt-3">&laquo; {{ $t('resetPassword.back') }}</v-btn>
			</v-card-text>
		</v-card> 
	</div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex'

export default {
	components: {
	},
	data: () => ({
		error_msg: '',
		valid: false,
		email: '',
		emailRules: [],
		message: null,
    verify_btn: false,
	}),
	methods: {
		send: function (event) {
		  event.preventDefault();
		  event.stopPropagation();
			if (this.$refs.form.validate()) {
				axios.post('/api/reset-password', {
					email: this.email,
					lang:this.$i18n.locale
				})
				.then(res=>{
					if(res.data.action==='ok') {
						this.message = res.data.message;	
					}
					else if(res.data.action==='error') {
						this.error_msg = res.data.message;	
					}
					else {
            this.error_msg = res.data.error;
            this.verify_btn = res.data.verify_btn !== undefined;
          }
				})
				.catch(function() {
					this.error_msg = this.$t('login.error_msg');
					this.error = true;
					this.verify_btn = false;
				});
			}
		},
    sendVerifyMail: function() {
      if (this.$refs.form.validate()) {
        axios.post('/api/email/resend', {
          email: this.email,
          lang:this.$i18n.locale
        })
            .then(res=>{
              this.error_msg = res.data.msg;
              this.error = true;
              this.verify_btn = false;
            })
            .catch(function() {
              this.error_msg = this.$t('login.error_msg');
              this.error = true;
              this.verify_btn = false;
            });
      }
    },
	},
	mounted() {
		if(this.logged) {
			this.$router.push('/'+this.$i18n.locale+'/login')
		}
    document.title = 'Passwort zurücksetzen - ' + this.$t('general.page_title');
		this.emailRules = [
			(v) => !!v || this.$t('register.emailRules1'),
			(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('register.emailRules2')
		];
	},
	computed: {
		...mapState(['logged'])
	},
};
</script>

<style scoped>
/deep/ .verify-button .v-btn__content { white-space: normal !important; flex: auto !important; }
.verify-button.verify-button.verify-button.verify-button.verify-button { height: auto; min-height:36px;}
</style>