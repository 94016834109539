<template>
	<div class="fill-height">
    <v-overlay v-if="askNewOrContinue">
      <v-card light class="mx-3">
        <v-card-title style="hyphens: auto">
          {{ $t('fragen.continue') }}
        </v-card-title>
        <v-card-actions right>
            <v-btn class="green white--text" @click="continueOld">{{ $t('general.ja') }}</v-btn>
            <v-btn class="red lighten-2 white--text" @click="startNew">{{  $t('general.nein') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
		<div v-if="options" class="black--text white pa-4 rounded">
			<h1 class="display-2 font-weight-thin" v-html="$t('fragen.header')"></h1>
			<br>
      <v-row>
        <v-col cols="12" sm="5" md="6">
          <v-img
              src="/uploads/checkstart.jpg"
              alt=""
          ></v-img>
        </v-col>
        <v-col cols="12" :sm="7" :md="6">
          <v-flex>
            <v-select required
              v-model="companySize"
              :items="companySizes"
              item-text="title"
              item-value="id"
              :label="$t('fragen.label_companySize')+'*'"
              @change="changeOption('companySize')"
            ></v-select>
          </v-flex>
          <v-flex>
            <v-select required
              v-model="branche"
              :items="branches"
              item-text="title"
              item-value="id"
              :label="$t('fragen.label_branche')+'*'"
              @change="changeOption('branche')"
            ></v-select>
          </v-flex>
          <v-flex>
            <v-select required
              v-model="location"
              :items="locations"
              item-text="title"
              item-value="id"
              :label="$t('fragen.label_location')"
              @change="changeOption('location')"
            ></v-select>
          </v-flex>
          <v-flex>
            <v-autocomplete
              v-model="zip"
              :label="$t('fragen.label_zip')"
              :items="ziplist"
              item-text="title"
              item-value="id"
              @change="changeOption('zip')"
            ></v-autocomplete>
          </v-flex>
          <v-flex>
            <label class="v-label theme--light" id="label-schichten">{{ $t('fragen.label_shift') }}</label>
            <v-select required
              v-model="shift"
              :items="shifts"
              item-text="title"
              item-value="id"
              :placeholder="'-- '+$t('general.placeholder')+' --'"
              @change="changeOption('shift')"
              class="pt-0"
              aria-labelledby="label-schichten"
            ></v-select>
          </v-flex>
          <v-flex>
            <label class="v-label theme--light" id="label-prozent">{{ $t('fragen.label_percentage') }}</label>
            <v-select required
              v-model="percentage"
              :items="percentages"
              item-text="title"
              item-value="id"
              :placeholder="'-- '+$t('general.placeholder')+' --'"
              @change="changeOption('percentage')"
              class="pt-0"
              aria-labelledby="label-prozent"
            ></v-select>
          </v-flex>
          <v-btn class="mr-4 mt-4 green--text" @click="showQuestions" :disabled="disabled_weiter" light>{{ $t('general.starten') }}</v-btn>
        </v-col>
      </v-row>
		</div>
		<div v-if="fragen" class="fill-height">
			<div v-if="questions" class="d-flex flex-column mb-auto fill-height">
				<v-carousel v-model="model"
                    hide-delimiters
                    :show-arrows="false"
                    eager
                    touchless
                    height="auto"
                    class="pb-10 overflow-visible rounded"
        >
					<v-carousel-item v-for="(item, index) in questions" :key="item.id" class="rounded overflow-hidden">
						<v-row class="black--text white pa-3">
							<v-col cols="12" sm="5" md="6" v-if="item.img">
								<v-img
									:src="item.img"
									class="grey lighten-2"
									aspect-ratio="1.7777777778"
									max-width="960"
									alt=""
								></v-img>
							</v-col>
							<v-col cols="12" :sm="(item.img? '7' : '12')" :md="(item.img? '6' : '12')">
								<div class="pt-0 pb-3 px-1">
									<h1 class="headline font-weight-thin black--text" v-html="enhancedText(item.title)"></h1>
									<v-radio-group v-model="answers[item.id]" v-if="Object.keys(item.options).length == 1" @change="setNext(index+1, item.id, 0)">
										<v-radio :label="$t('general.ja')"  value="1" class="mb-5" light></v-radio>
										<v-radio :label="item.negativoption" value="0" light></v-radio>
									</v-radio-group>
									<div v-if="Object.keys(item.options).length > 1">
										<div v-for="opt in item.options" :key="opt.id">
											<v-checkbox light @click.native="setNext(index+1, item.id, opt.id)" v-model="answers[item.id].option" :value="opt.id" hide-details>
                        <template v-slot:label>
                          <div v-html="enhancedText(opt.title)"></div>
                        </template>
                      </v-checkbox>
										</div>
										<v-checkbox light @click.native="setNext(index+1, item.id, 0)" v-model="answers[item.id].option" :label="item.negativoption" value="0" hide-details></v-checkbox>
									</div>
								</div>
							</v-col>
						</v-row>
					</v-carousel-item>
				</v-carousel>
				<v-alert type="error" v-if="q_error" dismissible>
          {{ $t('fragen.q_error') }}
        </v-alert>
				<v-footer padless color="green" class="mt-auto fill-height">
					<v-row justify="space-around" no-gutters class="pa-3 fill-height align-end">
						<v-btn @click="setModel(0)" primary fab small color="white" class="green--text" :disabled="prevdisabled">
							<v-icon>mdi-arrow-left</v-icon>
						</v-btn>
            <v-progress-linear
                v-model="progress"
                background-color="green darken-2"
                color="white"
                height="40"
                style="flex: 1"
                class="mr-4 ml-4 green--text rounded"
            >
                <strong>{{ progress }}%</strong>
            </v-progress-linear>
						<v-btn @click="setModel(1)" primary fab small color="white" class="green--text" :disabled="nextdisabled">
							<v-icon>mdi-arrow-right</v-icon>
						</v-btn>
						<v-btn primary fab small color="white" class="green--text ml-3" @click="getResults" v-if="result_btn"><v-icon>mdi-check</v-icon></v-btn>
					</v-row>
				</v-footer>
			</div>
			<div v-else>
				<p>{{ $t('fragen.keine_fragen') }}</p>
				<v-btn light class="ml-4" @click="newCheck">{{ $t('general.neu') }}</v-btn>
			</div>
		</div>
		<div v-if="show_results" id="auswertung-div" class="mb-3">
			<h1 class="display-2 font-weight-thin mb-3">{{ $t('fragen.header_results') }}</h1>
      <h2>{{ $t('fragen.subheading')}}</h2>
			<template v-if="results">
        <v-banner sticky class="white mb-3 rounded" elevation="2" mobile-breakpoint="355">
            <div class="headline align-self-center">{{ $t('fragen.gesamtergebnis') }}</div>
            <template v-slot:actions class="justify-end ml-0">
								<v-progress-circular
                  class="my-1 align-self-center"
									:rotate="-90"
									:size="60"
									:width="5"
									:value="results.total.percent"
									:color="(parseInt(results.total.percent)<50? 'red' : (parseInt(results.total.percent)<70? 'orange' : 'primary'))"
								>
									<span class="text-center">{{ $t('fragen.ist') }}<br>{{ results.total.percent }}</span>
								</v-progress-circular>
								<v-progress-circular
                  v-if="type == 'ag'"
                  class="my-1 align-self-center"
									:rotate="-90"
									:size="60"
									:width="5"
									:value="plan"
									:color="(parseInt(plan)<50? 'red' : (parseInt(plan)<70? 'orange' : 'primary'))"
									disabled
								>
									<span class="text-center">{{ $t('fragen.plan') }}<br>{{ plan }}</span>
								</v-progress-circular>
            </template>
        </v-banner>
				<v-card class="mb-3" v-if="parseInt(results.total.percent)>=70 && type=='ag'">
					<v-row no-gutters style="flex-wrap: nowrap;">
						<v-col class="flex-grow-0 flex-shrink-0">
							<v-img class="ma-3" src="/img/siegel/bsw_FamilyManager-Siegel_bronze.png" :alt="$t('fragen.bronze')" max-width="100"></v-img>
						</v-col>
						<v-col class="flex-grow-1 flex-shrink-0 pa-4">
              {{ $t('fragen.speichern') }}
						</v-col>
					</v-row>
				</v-card>
        <p>{{ $t('fragen.hinweis') }}</p>
        <v-expansion-panels accordion focusable :multiple="multiple">
					<v-expansion-panel v-for="(item,i) in results.themen" :key="i">
						<v-expansion-panel-header style="padding-right: 15px">
							<div class="row">
								<div class="headline font-weight-light col">{{item.title}}</div>
								<div v-if="type=='an' || logged" class="mr-5">
									<v-checkbox v-model="in_pdf" label="in PDF" :value="item.title" v-on:click.stop=""></v-checkbox>
								</div>
								<div class="mr-5 align-self-center">
									<v-progress-circular
										class="col"
										:rotate="-90"
										:size="50"
										:width="5"
										:value="item.percent"
										:color="(parseInt(item.percent)<50? 'red' : (parseInt(item.percent)<70? 'orange' : 'primary'))"
									>
										{{ item.percent }}
									</v-progress-circular>
								</div>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content v-if="item.actions">
							<v-data-table 
								v-model="selected[i]"
								:headers="headers" 
								:items="item.actions" 
								must-sort
								show-expand 
								:show-select="type=='ag'"
								item-key="id" 
								class="elevation-1 mt-3"
								hide-default-footer
								disable-pagination
								:sort-by="sortby"
								:sort-desc="sortdesc"
							>
                <template v-slot:no-data>
                    {{ $t('fragen.no_data') }}
                </template>
								<template v-if="type == 'ag'" v-slot:header.data-table-select="{ header }">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="d-inline-flex">
                        <span class="d-none d-sm-flex mr-1">{{ $t('fragen.plan') }}</span>
                        <v-icon color="green" small>mdi-help-circle</v-icon>
                      </span>
                    </template>
                    <span>{{ $t('fragen.hinweis2') }}</span>
                  </v-tooltip>
								</template>
								<template v-slot:header.title="{ header }">
									<span class="d-none d-sm-flex">{{ header.text }}</span>
								</template>
                <template v-slot:item.title="{ item }">
                  <span class="text-body-1 font-weight-bold text-sm-body-2 font-weight-sm-regular text-left">{{ item.title }}</span>
                </template>
								<template v-slot:item.weighting_factor="{ item }">
									<div class="p-2">
										<v-icon v-if="item.weighting_factor>7">mdi-arrow-up</v-icon>
										<v-icon v-else-if="item.weighting_factor>3">mdi-arrow-right</v-icon>
										<v-icon v-else>mdi-arrow-down</v-icon>
									</div>
								</template>
								<template v-slot:item.aufwand_zeit="{ item }">
									<div class="p-2">
										<v-icon v-if="item.aufwand_zeit==2">mdi-arrow-up</v-icon>
										<v-icon v-else-if="item.aufwand_zeit==1">mdi-arrow-right</v-icon>
										<v-icon v-else>mdi-arrow-down</v-icon>
									</div>
								</template>
								<template v-slot:item.aufwand_finanz="{ item }">
									<div class="p-2">
										<v-icon v-if="item.aufwand_finanz==2">mdi-arrow-up</v-icon>
										<v-icon v-else-if="item.aufwand_finanz==1">mdi-arrow-right</v-icon>
										<v-icon v-else>mdi-arrow-down</v-icon>
									</div>
								</template>
								<template v-slot:expanded-item="{ item }">
									<td :colspan="headers.length" class="pt-3">
										<v-row>
											<v-col cols="12" sm="5">
                        <strong>{{ $t('fragen.description') }}</strong>
                        <div v-html="item.description"></div>
                      </v-col>
											<v-col cols="12" sm="6">
												<strong>{{ $t('fragen.responsible') }}:</strong> {{item.responsible}}<br>
												<strong>{{ $t('fragen.jurisdiction') }}:</strong> {{item.jurisdictions}}<br>
                        <div v-if="item.partners && item.partners.length > 0">
                          <p class="pt-3">
                            <strong>{{ $t('fragen.partners') }}:</strong>
                          </p>
                          <div v-for="itm in item.partners" :key="itm.id">
                            <p>
                              <strong>{{itm.institution}}</strong><br>
                              <span>{{itm.firstname}} {{itm.lastname}}</span><br>
                              <span>{{itm.email}}</span>
                            </p>
                          </div>
                        </div>
                        <div v-if="item.practises" class="font-weight-bold mt-3">{{ $t('fragen.practise') }}:</div>
												<div v-for="itm in item.practises" :key="itm.id" class="py-2">
													<div v-if="itm.title" class="font-weight-medium">{{itm.title}}</div>
													<div v-if="itm.description" v-html="itm.description" class="practise-descr"></div>
													<v-btn 
														v-for="(media, ind, ind2) in itm.media"
														:key="`${itm.id}-${ind2}`"
														class="my-2 mr-2" 
														outlined color="primary" 
														:href="media.href"
														target="_blank"
													>
														<v-icon v-if="media.ext=='mp4'">mdi-video</v-icon>
														<v-icon v-else-if="media.ext=='mp3'">mdi-volume-low</v-icon>
														<v-icon v-else-if="media.ext=='wav'">mdi-volume-low</v-icon>
														<v-icon v-else-if="media.ext=='pdf'">mdi-file-pdf-box</v-icon>
													</v-btn>
													<v-btn 
														v-if="itm.url"
														class="my-2 mr-2" 
														outlined color="primary" 
														:href="itm.url"
														target="_blank"
													>
														<v-icon>mdi-link</v-icon>
													</v-btn>
												</div>
											</v-col>
										</v-row>
									</td>
								</template>
							</v-data-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<v-alert :type="alertType" class="mt-5" v-if="logged && showAlert">{{alertMsg}}</v-alert>
				<v-row justify="end" class="mt-5" v-if="type=='ag'">
					<v-btn light class="ml-3" @click="editAnswers" v-if="questions">&laquo; {{ $t('general.edit') }}</v-btn>
					<v-btn light class="ml-3" @click="newCheck">{{ $t('general.neu') }}</v-btn>
					<div class="flex-grow-1"></div>
					<v-btn light class="mr-3 green--text" @click="saveResults" v-if="logged && showSave">{{ $t('general.save') }}</v-btn>
					<v-btn light class="mr-3 green--text" @click="loginUndSaveResults" v-if="!logged">{{ $t('fragen.login_save') }}</v-btn>
          <v-btn light class="ml-3 mr-3" @click="getPDF" v-if="logged"><v-icon>mdi-file-pdf</v-icon> PDF (Plan)</v-btn>
				</v-row>
				<v-row justify="end" class="mt-5" v-if="type=='an'">
					<v-btn light class="ml-3" @click="editAnswers" v-if="questions">&laquo; {{ $t('general.edit') }}</v-btn>
					<v-btn light class="ml-3" @click="newCheck">{{ $t('general.neu') }}</v-btn>
					<div class="flex-grow-1"></div>
					<v-btn light class="ml-3 mr-3" @click="getPDF" v-if="type=='an'"><v-icon>mdi-file-pdf</v-icon> PDF</v-btn>
				</v-row>
			</template>
		</div>
		<v-overlay :value="overlay">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
//import {i18n} from "../main";

export default {
	components: {
	},
	data: () => ({
		options: 1,
		disabled_weiter: true,
		fragen: 0,
		show_results: false,
		model: 0,
		branche: null,
		companySize: null,
		location: null,
		zip: null,
		percentage: null,
		shift: null,
		contract: false,
		branches: [],
		companySizes: [],
		locations: [],
		percentages: [],
		shifts: [],
		questions: [],
		relations: [],
    	glossary: {},
		ziplist: [],
		page: 0,
		answers: null,
		result_btn: false,
		answered: [],
		cur_next: false,
		results: [],
		in_pdf: [],
		plan: 0,
		showSave: true,
		showAlert: false,
		alertMsg: '',
		alertType: 'success',
		type: null,
		expanded: [],
		multiple:true,
		headers:[],
		selected: [],
		lightBoxOptions: {
			onopen: function() {
				const el = document.getElementsByClassName("v-content")[0];
				el.classList.add('contentzindex');
			},
			onclosed: function() {
				const el = document.getElementsByClassName("v-content")[0];
				el.classList.remove('contentzindex');
			},
			enableKeyboardNavigation: false,
			disableScroll: true,
		},
		sortby: ['weighting_factor'],
		sortdesc: [true],
		overlay: false,
		q_error: false,
    askNewOrContinue: false
	}),
	methods: {
		...mapActions(['setLastCheck', 'setLastAnswers', 'setLastOptions']),
    continueOld() {
		  this.askNewOrContinue = false;
    },
    startNew() {
      this.clearSessionstorage();
      window.location.reload(true);
		  this.askNewOrContinue = false;
    },
    clearSessionstorage() {
      sessionStorage.removeItem(this.type + '_fragen');
      sessionStorage.removeItem(this.type + '_model');
      sessionStorage.removeItem(this.type + '_answers');
      sessionStorage.removeItem(this.type + '_answered');
      sessionStorage.removeItem(this.type + '_result_btn');
      sessionStorage.removeItem(this.type + '_questions');
      sessionStorage.removeItem(this.type + '_cur_next');
      sessionStorage.removeItem(this.type + '_companySize');
      sessionStorage.removeItem(this.type + '_location');
      sessionStorage.removeItem(this.type + '_branche');
      sessionStorage.removeItem(this.type + '_zip');
      sessionStorage.removeItem(this.type + '_percentage');
      sessionStorage.removeItem(this.type + '_shift');
    },
		changeOption() {
			if(this.companySize && this.branche) {
				this.disabled_weiter = false;
				axios.post('/api/getQuestions', {
					branche: this.branche,
					companySize: this.companySize,
					location: this.location,
					zip: this.zip,
					percentage: this.percentage,
					shift: this.shift,
					contract: this.contract,
					type: this.type,
					lang:this.$i18n.locale
				})
				.then(response => {
					this.questions = response.data.questions;
					this.relations = response.data.relations;
					this.glossary = response.data.glossary;
          this.createTooltipBubbles();
					this.page = 1;
					if(!this.answers) {
						let answers = {};
						this.questions.forEach(function(item){
							if(Object.keys(item.options).length>1) {
								answers[item.id] = {option:[]};
							} else {
								answers[item.id]=null;
							}
						});
						this.answers = answers;	
					}
				});
			}
		},
		showQuestions() {
			this.options = 0;
			this.fragen = 1;
			sessionStorage.setItem(this.type + '_fragen',1);
    },
		newCheck() {
		  sessionStorage.clear();
			this.setLastCheck('');
			localStorage.removeItem('lastCheck');
			this.setLastAnswers('');
			localStorage.removeItem('lastAnswers');
			this.setLastOptions('');
			localStorage.removeItem('lastOptions');
			this.options = 1;
			this.disabled_weiter = true;
			this.fragen = 0;
			this.show_results = false;
			this.model = 0;
			this.branche = null;
			this.companySize = null;
			this.location = null;
			this.zip = null;
			this.percentage = null;
			this.shift = null;
			this.contract = false;
			this.type = this.$route.params.type;
			this.questions = [];
			this.page = 0;
			this.answers = null;
			this.result_btn = false;
			this.answered = [];
			this.cur_next = false;
			this.results = [];
			this.showSave = true;
			this.showAlert = false;
		},
		editAnswers() {
			this.options = 0;
			this.fragen = 1;
			
			this.show_results = false;
		},
		getResults() {
			var answered = 0;

			//var answers = this.answers;
			for (const value of Object.entries(this.answers)) {
				if(value[1] instanceof Object) {
					try {
						value[1].option.forEach(function(element) {
							if(element!=='0') {
								answered++;
							}
						})
					}
					catch (e) {
						continue;
					}
				} else {
					if(value[1]==='1') {
						answered++;
					}	
				}
				
			}

			if(answered==0) {
				this.q_error = true;
			} else {
				this.q_error = false;
				this.overlay = true;
				axios.post('/api/getCheckResults', {
					answers: this.answers,
					branche: this.branche,
					companySize: this.companySize,
					location: this.location,
					zip: this.zip,
					percentage: this.percentage,
					shift: this.shift,
					contract: this.contract,
					type: this.type,
					lang:this.$i18n.locale
				})
				.then(response => {
					this.results = response.data.results;
					if (this.results.themen) {
						this.results.themen.forEach( function(item) {
							this.in_pdf.push(item.title);
						}, this);
					}
					this.setLastCheck(this.results);
					localStorage.setItem('lastCheck', JSON.stringify(this.results));
					this.setLastAnswers(this.answers);
					localStorage.setItem('lastAnswers', JSON.stringify(this.answers));
					this.setLastOptions(this.results);
					localStorage.setItem('lastOptions', JSON.stringify({
						branche: this.branche,
						companySize: this.companySize,
						location: this.location,
						zip: this.zip,
						percentage: this.percentage,
						shift: this.shift,
						contract: this.contract,
						type: this.type
					}));
					this.fragen = 0;
					this.plan = this.results.total.percent;
					this.show_results = true;
					this.overlay = false;
					this.clearSessionstorage();
				});
			}
		},
		saveResults() {
			this.overlay = true;
			this.setLastCheck(this.results);
			localStorage.setItem('lastCheck', JSON.stringify(this.results));
			
			axios.post('/api/saveResults', {
				results: this.results,
				answers: this.answers,
				branche: this.branche,
				companySize: this.companySize,
        location: this.location,
				zip: this.zip,
				percentage: this.percentage,
				shift: this.shift,
				contract: this.contract,
				type: this.type,
				lang:this.$i18n.locale
			})
			.then(response => {
				if(response.data.action=='ok') {
					this.setLastCheck('');
					localStorage.removeItem('lastCheck');
					this.setLastAnswers('');
					localStorage.removeItem('lastAnswers');
					this.setLastOptions('');
					localStorage.removeItem('lastOptions');
					this.alertType = 'success';
					this.alertMsg = response.data.msg;
					this.showAlert = true;
					this.showSave = false;	
					this.questions = false;

          this.$router.push('/'+this.$i18n.locale+'/ergebnisse?check=' + response.data.check);
				} else {
					if(response.data.msg != undefined) {
						this.alertMsg = response.data.msg;
					} else {
						this.alertMsg = this.$t('general.error');
					}
					this.alertType = 'error';
					this.showAlert = true;
				}
				this.overlay = false;
			})
			.catch(function(error) {
				if (error.response && error.response.status === 401) {
					//this.$router.push('/login');
					window.location.href = '/'+this.$i18n.locale+'/login';
				} else {
					this.alertMsg = this.$t('general.error');
					this.alertType = 'error';
					this.showAlert = true;
				}
				this.overlay = false;
			});
		},
		loginUndSaveResults() {
			this.setLastCheck(this.results);
			localStorage.setItem('lastCheck', JSON.stringify(this.results));
			this.setLastAnswers(this.answers);
			localStorage.setItem('lastAnswers', JSON.stringify(this.answers));
			this.setLastOptions(this.results);
			localStorage.setItem('lastOptions', JSON.stringify({
				branche: this.branche,
				companySize: this.companySize,
				location: this.location,
				zip: this.zip,
				percentage: this.percentage,
				shift: this.shift,
				contract: this.contract,
				type: this.type
			}));
			this.$router.push('/'+this.$i18n.locale+'/login');
		},
		setModel(q) {
      window.scrollTo(0,0);
			var i;
			if(q) {
				this.model++;
				for(i=this.model; i<this.questions.length; i++) {
					if(this.questions[i].show) {
						this.model = i;
						break;
					}
				}
			} else {
				this.model--;
				for(i=this.model; i>=0; i--) {
					if(this.questions[i].show) {
						this.model = i;
						break;
					}
				}
			}
			if((this.model+1)==this.questions.length) {
				this.cur_next = false;
				if((this.model+1)==this.questions.length && !(this.answered[(this.model+1)]===undefined || this.answered[(this.model+1)]===0)) {
					this.result_btn = true;
				}
			} else {
				this.cur_next = !(this.answered[(this.model+1)]===undefined || this.answered[(this.model+1)]===0) ;	
			}
		},
		setNext(index, id, optid) {
			this.q_error = false;
			var questions = this.questions;
			var answers = this.answers;
			var answered = this.answered;
			var result_btn = this.result_btn;
			
			if(this.answers[id].option !== undefined){
				if(this.answers[id].option.length==0) {
					answered[index] = 0;
					this.cur_next = false;
					this.result_btn = false;
				} else {
					answered[index] = 1;
					if((this.model+1)<this.questions.length) {
						this.cur_next = true;
					}
					if((this.model+1)==this.questions.length) {
						this.result_btn = true;
					}
					if(this.page<=index && this.page<this.questions.length) {
						this.page = index+1;
					}
					if(optid==0) {
						this.answers[id].option = ["0"];
					} else {
						if(this.answers[id].option.includes("0")) {
							this.answers[id].option = [optid];
						}
					}
					if(this.relations[id] !== undefined) {
						this.relations[id].forEach(function(element) {
							var el_index = questions.findIndex(obj => {
								return obj.id === element.id
							});
							
							if(el_index>-1) {
								var array_intersection = element.related_options.filter(function(x) {
									if(answers[id].option.indexOf(x) != -1)
										return true;
									else
										return false;
								});

								if(element.show_condition==1) {
									if(element.relation==1) {
										if(optid==0) {
											if(!questions[el_index].show) {
												result_btn = false;
											}
											questions[el_index].show = true;
										} else {
											questions[el_index].show = false;
											try {
												answered[el_index+1] = 0;
											} catch(err) { this.$refs.refNameOfObserver.setErrors(err.response.data.errors); }
											if(questions[el_index].options.length>1) {
												answers[element.id] = {option:[]};	
											} else {
												answers[element.id] = null;
											}
										}
									} else if(element.relation==2) {
										if(array_intersection.length) {
											if(!questions[el_index].show) {
												result_btn = false;
											}
											questions[el_index].show = true;	
										} else {
											questions[el_index].show = false;
											try {
												answered[el_index+1] = 0;
											} catch(err) { this.$refs.refNameOfObserver.setErrors(err.response.data.errors); }
											if(questions[el_index].options.length>1) {
												answers[element.id] = {option:[]};	
											} else {
												answers[element.id] = null;
											}
										}
									}
								} else if(element.show_condition==2) {
									if(element.relation==1) {
										if(optid==0) {
											questions[el_index].show = false;
											try {
												answered[el_index+1] = 0;
											} catch(err) { this.$refs.refNameOfObserver.setErrors(err.response.data.errors); }
											if(questions[el_index].options.length>1) {
												answers[element.id] = {option:[]};	
											} else {
												answers[element.id] = null;
											}
										} else {
											if(!questions[el_index].show) {
												result_btn = false;
											}
											questions[el_index].show = true;
										}
									} else if(element.relation==2) {
										if(array_intersection.length) {
											questions[el_index].show = false;
											try {
												answered[el_index+1] = 0;
											} catch(err) { this.$refs.refNameOfObserver.setErrors(err.response.data.errors); }
											if(questions[el_index].options.length>1) {
												answers[element.id] = {option:[]};	
											} else {
												answers[element.id] = null;
											}
										} else {
											if(!questions[el_index].show) {
												result_btn = false;
											}
											questions[el_index].show = true;
										}
									}
								}
							}
						});
						this.answers = answers;
						this.answered = answered;
						this.result_btn = result_btn;
						this.questions = questions;
					}
				}
			} else {
				answered[index] = 1;
				if((this.model+1)<this.questions.length) {
					this.cur_next = true;
				}
				if((this.model+1)==this.questions.length) {
					this.result_btn = true;
				}
				if(this.page<=index && this.page<this.questions.length) {
					this.page = index+1;
				}
				
				if(this.relations[id] !== undefined) {
					this.relations[id].forEach(function(element) {
						
						var el_index = questions.findIndex(obj => {
							return obj.id === element.id
						});
						
						if(el_index>-1) {
							if(element.show_condition==1) {
								if(element.relation==1) {
									if(answers[id]==0) {
										if(!questions[el_index].show) {
											result_btn = false;
										}
										questions[el_index].show = true;
									} else {
										questions[el_index].show = false;
										try {
											answered[el_index+1] = 0;
										} catch(err) { this.$refs.refNameOfObserver.setErrors(err.response.data.errors); }
										if(questions[el_index].options.length>1) {
											answers[element.id] = {option:[]};	
										} else {
											answers[element.id] = null;
										}
									}
								} else if(element.relation==2) {
									if(answers[id]==1) {
										if(!questions[el_index].show) {
											result_btn = false;
										}
										questions[el_index].show = true;	
									} else {
										questions[el_index].show = false;
										try {
											answered[el_index+1] = 0;
										} catch(err) { this.$refs.refNameOfObserver.setErrors(err.response.data.errors); }
										answers[element.id] = {option:[]};
									}
								}
							} else if(element.show_condition==2) {
								if(element.relation==1) {
									if(answers[id]==0) {
										questions[el_index].show = false;
										try {
											answered[(el_index+1)] = 0;
										} catch(err) { this.$refs.refNameOfObserver.setErrors(err.response.data.errors); }
										if(questions[el_index].options.length>1) {
											answers[element.id] = {option:[]};	
										} else {
											answers[element.id] = null;
										}
									} else {
										if(!questions[el_index].show) {
											result_btn = false;
										}
										questions[el_index].show = true;
									}
								} else if(element.relation==2) {
									if(answers[id]==1) {
										questions[el_index].show = false;
										try {
											answered[el_index+1] = 0;
										} catch(err) { this.$refs.refNameOfObserver.setErrors(err.response.data.errors); }
										if(questions[el_index].options.length>1) {
											answers[element.id] = {option:[]};	
										} else {
											answers[element.id] = null;
										}
									} else {
										if(!questions[el_index].show) {
											result_btn = false;
										}
										questions[el_index].show = true;
									}
								}
							}
						}
						
					});
					this.answers = answers;
					this.answered = answered;
					this.result_btn = result_btn;
					this.questions = questions;
				}
			}

			sessionStorage.setItem(this.type + '_model', JSON.stringify(this.model));
			sessionStorage.setItem(this.type + '_answers', JSON.stringify(this.answers));
			sessionStorage.setItem(this.type + '_answered', JSON.stringify(this.answered));
			sessionStorage.setItem(this.type + '_result_btn', JSON.stringify(this.result_btn));
			sessionStorage.setItem(this.type + '_questions', JSON.stringify(this.questions));
			sessionStorage.setItem(this.type + '_cur_next', JSON.stringify(this.cur_next));

		},
    getPDF() {
      var selected = [];
      this.selected.forEach(function (item) {
        item.forEach(function (action) {
          selected.push(action.id);
        });
      });

      let themen = this.results.themen;

      if (this.in_pdf.length > 0) {
		  themen = [];

		  this.results.themen.forEach(function (item) {
			  if (this.in_pdf.indexOf(item.title) >= 0) {
				  themen.push(item);
			  }
		  }, this);
	  }

      axios({
        url: '/api/getPdf',
        method: 'POST',
        responseType: 'blob',
        data: {
          lang:this.$i18n.locale,
          current: this.results.total.percent,
          planned: this.plan,
		  in_pdf: this.in_pdf,
          company: this.type === 'ag',
          themen:   themen,
          selected: selected
        }
      })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'Family_Manager_Auswertung';
            link.click()
            URL.revokeObjectURL(link.href)
          });

    },
    slug (str) {
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñçß·/_,:;";
      var to   = "aaaaeeeeiiiioooouuuuncs------";
      for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-'); // collapse dashes

      return str;
    },
    createTooltipBubbles() {
      for (let word in this.glossary) {
        if (!document.querySelector('id-' + this.slug(word)) && word.length > 0) {
          let bubble = document.createElement('div');
          bubble.id = 'id-' + this.slug(word);
          bubble.setAttribute('role', 'status');
          bubble.classList.add('tooltip', 'visually-hidden');
          bubble.innerHTML =
              '<div class="v-card v-sheet theme--light">' +
              '<div class="v-list v-sheet theme--light" style="padding-left: 0.5rem; padding-right: 0.5rem">' +
              this.glossary[word] +
              '</div>' +
              '</div>';
          this.removeBubbleFromScreen(bubble);
          document.getElementById('app').appendChild(bubble);
        }
      }
    },
    enhancedText(text) {
		  let enhanced = text;
		  for (let word in this.glossary) {
		    if (word.length > 0) {
          let regex = new RegExp(word, 'g');
          enhanced = enhanced.replace(regex, word + '<button data-tooltip="id-' + this.slug(word) + '" class="v-icon notranslate v-icon--link mdi mdi-information green--text" type="button" aria-label="Mehr dazu"></button>');
        }
      }
		  return enhanced;
    },
    openTooltip(button, bubble) {
      this.showBubbleOnScreen(bubble);
      this.calculatePosition(button, bubble);
    },
    closeTooltips() {
		  document.querySelectorAll('.tooltip').forEach(function(bubble)
      {
        this.removeBubbleFromScreen(bubble);
      }, this);
    },
    removeBubbleFromScreen(bubble) {
		  bubble.classList.add('visually-hidden');
    },
    showBubbleOnScreen(bubble) {
		  bubble.classList.remove('visually-hidden');
    },
    calculatePosition(button, bubble) {
      const rect_button = button.getBoundingClientRect();
      this.showBubbleOnScreen(bubble);
      const bubble_width = bubble.getBoundingClientRect().width;
      const bubble_height = bubble.getBoundingClientRect().height;

      let top = 0;
      let left = 0;
      
      top = rect_button.top + window.pageYOffset + rect_button.height -2;
      left = rect_button.left + 0.5 * rect_button.width - 0.5 * bubble_width;

      //bottom position is off the screen, display bubble above the button
      if (top + bubble_height > window.pageYOffset + window.innerHeight) {
        top = rect_button.top - bubble_height +2;
      }

      //to far to the right
      if (left + bubble_width > window.innerWidth) {
        left = window.innerWidth - bubble_width +2;
      }

      //to far to the left
      if (left < 0) {
        left = -2;
      }   
      
      bubble.style.setProperty('top', top + 'px');
      bubble.style.setProperty('left', left + 'px');
    }
	},
	mounted() {
    document.title = this.$t('menu.fragen') + ' - ' + this.$t('general.page_title');
		this.type = this.$route.params.type;
		
		if(this.type!='an' && this.type!='ag') {
			this.$router.push('/'+this.$i18n.locale);
		}
		localStorage.setItem('usertype', this.type);
		
		this.headers = [
			{
				text: this.$t('fragen.action'),
				align: 'start',
				sortable: false,
				value: 'title',
			},
			{ text: this.$t('fragen.weighting_factor'), value: 'weighting_factor' },
			{ text: this.$t('fragen.aufwand_zeit'), value: 'aufwand_zeit' },
			{ text: this.$t('fragen.aufwand_finanz'), value: 'aufwand_finanz' },
			{ text: '', value: 'data-table-expand' },
		];
		
		var lastOptions = this.lastOptions;
		
		if(!lastOptions || lastOptions.type===undefined) {
			lastOptions = JSON.parse(localStorage.getItem('lastOptions'));
		}
		if(lastOptions && lastOptions.type!=this.type) {
			this.$router.push('/'+this.$i18n.locale);
		}
		
		if(this.lastCheck) {
			this.options = 0;
			this.results = this.lastCheck;
			if (this.results.themen) {
				this.results.themen.forEach( function(item) {
					this.in_pdf.push(item.title);
				}, this);
			}
			try {this.plan = this.results.total.percent;}catch(e){this.plan = 0;}
			this.fragen = 0;
			this.show_results = true;
			this.questions = null;
		}

		axios.post('/api/getOptions', {lang:this.$i18n.locale})
		.then(response => {
			this.branches = response.data.branches;
			this.companySizes = response.data.companysizes;
			this.locations = response.data.locations;
			this.percentages = response.data.percentages;
			this.shifts = response.data.shifts;
			this.ziplist = response.data.ziplist;

			if(sessionStorage.getItem(this.type + '_companySize') && !!sessionStorage.getItem(this.type + '_companySize')) {
			  this.askNewOrContinue = true;
      }

      this.companySize = +sessionStorage.getItem(this.type + '_companySize');
      if (!this.companySize) {
        this.companySize = response.data.companysize;
      }
      this.branche = +sessionStorage.getItem(this.type + '_branche');
      if (!this.branche) {
        this.branche = response.data.branche;
      }
      this.location = +sessionStorage.getItem(this.type + '_location');
      if (!this.location) {
        this.location = response.data.location;
      }
      this.zip = +sessionStorage.getItem(this.type + '_zip');
      if (!this.zip) {
        this.zip = response.data.zip;
      }
      this.shift = +sessionStorage.getItem(this.type + '_shift');
      this.percentage = +sessionStorage.getItem(this.type + '_percentage');

			this.changeOption();

      this.fragen = +sessionStorage.getItem(this.type + '_fragen');
      if (this.companySize != null && this.branche !=null && this.fragen == 1) {
        this.showQuestions();

        if (sessionStorage.getItem(this.type + '_model')) {
          this.model = JSON.parse(sessionStorage.getItem(this.type + '_model'));
        }
        if (sessionStorage.getItem(this.type + '_answers')) {
          this.answers = JSON.parse(sessionStorage.getItem(this.type + '_answers'));
        }
        if (sessionStorage.getItem(this.type + '_answered')) {
          this.answered = JSON.parse(sessionStorage.getItem(this.type + '_answered'));
        }
        if (sessionStorage.getItem(this.type + '_result_btn')) {
          this.result_btn = JSON.parse(sessionStorage.getItem(this.type + '_result_btn'));
        }
        if (sessionStorage.getItem(this.type + '_questions')) {
          this.questions = JSON.parse(sessionStorage.getItem(this.type + '_questions'));
        }
        if (sessionStorage.getItem(this.type + '_cur_next')) {
          this.cur_next = JSON.parse(sessionStorage.getItem(this.type + '_cur_next'));
        }

      }
		});

    let that = this;
    document.addEventListener('click', function(event) {
      if (event.target && event.target.hasAttribute('data-tooltip')) {
        // fix to keep checkboxes state unchanged
        event.target.parentElement.click();

        let bubble = document.getElementById(event.target.getAttribute('data-tooltip'));
        if(bubble.classList.contains('visually-hidden')) {
          that.openTooltip(
              event.target,
              bubble
          );
        }
        return false;
      }
      that.closeTooltips();
    });
    document.addEventListener('mouseenter', function(event) {
      if (event.target.hasAttribute('data-tooltip') && window.innerWidth > 600) {
        let bubble = document.getElementById(event.target.getAttribute('data-tooltip'));
        if(bubble.classList.contains('visually-hidden')) {
          that.openTooltip(
              event.target,
              bubble
          );
        }
        return false;
      }
    }, true);
    document.addEventListener('mouseleave', function(event) {
      if (event.target.hasAttribute('data-tooltip') && window.innerWidth > 600) {
        that.closeTooltips();
      }
    }, true);
  },
	watch: {
    '$i18n.locale' : function() {
      axios.post('/api/getOptions', {lang:this.$i18n.locale})
          .then(response => {
            this.branches = response.data.branches;
            this.companySizes = response.data.companysizes;
            this.locations = response.data.locations;
            this.percentages = response.data.percentages;
            this.shifts = response.data.shifts;
            this.ziplist = response.data.ziplist;

            this.changeOption();
          });

      if(this.companySize && this.branche) {
        this.disabled_weiter = false;
        axios.post('/api/getQuestions', {
          branche: this.branche,
          companySize: this.companySize,
          location: this.location,
          zip: this.zip,
          percentage: this.percentage,
          shift: this.shift,
          contract: this.contract,
          type: this.type,
          lang:this.$i18n.locale
        })
            .then(response => {
              this.questions = response.data.questions;
              this.relations = response.data.relations;
              this.glossary = response.data.glossary;
              this.createTooltipBubbles();
              this.page = 1;
              if(!this.answers) {
                let answers = {};
                this.questions.forEach(function(item){
                  if(Object.keys(item.options).length>1) {
                    answers[item.id] = {option:[]};
                  } else {
                    answers[item.id]=null;
                  }
                });
                this.answers = answers;
              }
            });
      }

      if(this.show_results) {
        axios.post('/api/getCheckResults', {
          answers: this.answers,
          branche: this.branche,
          companySize: this.companySize,
          location: this.location,
          zip: this.zip,
          percentage: this.percentage,
          shift: this.shift,
          contract: this.contract,
          type: this.type,
          lang:this.$i18n.locale
        })
            .then(response => {
              this.results = response.data.results;
              this.setLastCheck(this.results);
              localStorage.setItem('lastCheck', JSON.stringify(this.results));
              this.setLastAnswers(this.answers);
              localStorage.setItem('lastAnswers', JSON.stringify(this.answers));
              this.setLastOptions(this.results);
              localStorage.setItem('lastOptions', JSON.stringify({
                branche: this.branche,
                companySize: this.companySize,
                location: this.location,
                zip: this.zip,
                percentage: this.percentage,
                shift: this.shift,
                contract: this.contract,
                type: this.type
              }));
              this.fragen = 0;
              this.plan = this.results.total.percent;
              this.show_results = true;
              this.overlay = false;
            });
      }

    },
		companySize : function (val) {
			this.companySize = val;
			sessionStorage.setItem(this.type + '_companySize', val);
		},
		location : function (val) {
			this.location = val;
      sessionStorage.setItem(this.type + '_location', val);
		},
		branche : function (val) {
			this.branche = val;
      sessionStorage.setItem(this.type + '_branche', val);
		},
		zip : function (val) {
			this.zip = val;
      sessionStorage.setItem(this.type + '_zip', val);
		},
		percentage : function (val) {
			this.percentage = val;
      sessionStorage.setItem(this.type + '_percentage', val);
		},
		shift : function (val) {
			this.shift = val;
      sessionStorage.setItem(this.type + '_shift', val);
		},
		contract : function (val) {
			this.contract = val;
		},
		selected: function() {
			if(this.selected.length) {
				var selected = [];
				this.selected.forEach(function(item){
					item.forEach(function(action){
						selected.push(action.id);
					});
				});
				
				var lastOptions = JSON.parse(localStorage.getItem('lastOptions'));
				axios.post('/api/getNewCheckResults', {
					answers: JSON.parse(localStorage.getItem('lastAnswers')),
					branche: lastOptions.branche,
					companySize: lastOptions.companySize,
					location: lastOptions.location,
					zip: lastOptions.zip,
					percentage: lastOptions.percentage,
					shift: lastOptions.shift,
					contract: lastOptions.contract,
					type: lastOptions.type,
					selected: selected,
					lang:this.$i18n.locale
				})
				.then(response => {
					this.plan = response.data.results.total.percent;
				});
			} else {
				this.plan = this.results.total.percent;
			}
			
		},
	},
	computed: {
	  progress: function() {
      return parseInt(this.model * 100/this.questions.length);
    },
		...mapState(['logged', 'name', 'lastCheck', 'lastOptions']),
		prevdisabled: function() {
			return (this.model<1)
		},
		nextdisabled: function() {
			return !this.cur_next;
		},
	}
};
</script>
<style>
.border-primary {border: 1px solid #419d4b;}
.v-application .caption p {
    margin-bottom: 0;
}
.v-expansion-panel-content__wrap {padding: 0 !important;}
.v-data-table .v-data-table__mobile-row {
    height: initial;
    min-height: 36px !important;
}
.v-data-table__mobile-row__header {font-weight:400 !important;}
.v-data-table__mobile-table-row .v-data-table__mobile-row:first-child + .v-data-table__mobile-row .v-data-table__mobile-row__header,
.v-data-table__mobile-table-row .v-data-table__mobile-row:last-child .v-data-table__mobile-row__header 
{
    display: none;
}
/* .v-data-table tbody tr.v-data-table__expanded__content { box-shadow: none !important;} */
.v-data-table__mobile-table-row .v-data-table__mobile-row:first-child .v-data-table__mobile-row__cell {
	font-size: 1rem;
	font-weight: 600 !important;
	padding-top: 1rem;
    text-align: left !important;
}
.v-data-table__mobile-table-row .v-data-table__mobile-row:last-child .v-data-table__mobile-row__cell {
	text-align: center;
	width: 100%;
}
.contentzindex {z-index: 7; position: fixed;width: 100%;}
.blueimp-gallery-controls>.next, .blueimp-gallery-controls>.prev {
    display: none;
}
.practise-descr p:last-child {margin-bottom: 0;}

.tooltip p {
  margin-bottom: 0;
}

.theme--light.v-label {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 300;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.tooltip {
  position: absolute;
}
</style>
<style scoped>
  .v-banner--sticky { top: 70px !important;z-index: 2 !important;}

  /deep/ .v-data-table-header__icon {
    transform: none;
    opacity: 1;
  }

  .headline {
    font-size: 2.125rem;
    line-height: 2.5rem;
  }

  /deep/ .v-input--selection-controls .v-input__slot > .v-label,
  /deep/ .v-input--selection-controls .v-radio > .v-label {
    display: block;
  }

  /deep/ .v-data-table [role="columnheader"]:nth-child(3),
  /deep/ .v-data-table [role="columnheader"]:nth-child(4),
  /deep/ .v-data-table [role="columnheader"]:nth-child(5) {
    width: 12%;
  }

  @media (min-width: 600px) {
    /deep/ .font-weight-sm-regular {
      font-weight: 400 !important;
    }
  }

  /deep/ .v-data-table__mobile-row__cell.v-data-table__mobile-row__cell .text-body-1 {
    display: inline-block;
    padding-right: 50px;
    position: relative;
    text-align: left;
    top: -28px;
  }

  /deep/ .v-data-table__checkbox {
    z-index: 1;
  }
</style>