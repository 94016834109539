<template>
	<div>
    <h1 class="display-2 font-weight-thin mb-6">Dashboard</h1>
    <template v-if="logged">

      <v-card class="pa-3">
          <div v-if="siegel">
            <v-row>
              <template v-if="siegel.ja && siegel.gold!='0' && siegel.gold!='1'">
                  <v-col cols="12" sm="3" class="flex-grow-0 flex-shrink-0 mb-6 text-center d-flex flex-column justify-sm-start justify-md-center">
                    <v-img :src="siegel.src" alt="" width="200" class="mb-1 ml-auto mr-auto" style="flex: 0 0 auto"></v-img>
                    Gültig bis: {{ siegel.gold }}
                  </v-col>
              </template>
              <template v-else-if="siegel.ja && siegel.silber!='0' && siegel.silber!='1'">
                  <v-col cols="12" sm="3" class="flex-grow-0 flex-shrink-0 mb-6 text-center d-flex flex-column justify-sm-start justify-md-center">
                    <v-img :src="siegel.src" alt="" width="200" class="mb-1 ml-auto mr-auto" style="flex: 0 0 auto"></v-img>
                    Gültig bis: {{ siegel.silber }}
                  </v-col>
              </template>
              <template v-else-if="siegel.ja && siegel.bronze!='0' && siegel.bronze!='1'">
                  <v-col cols="12" sm="3" class="flex-grow-0 flex-shrink-0 mb-6 text-center d-flex flex-column justify-sm-start justify-md-center">
                    <v-img :src="siegel.src" alt="" width="200" class="mb-1 ml-auto mr-auto" style="flex: 0 0 auto"></v-img>
                    Gültig bis: {{ siegel.bronze }}
                  </v-col>
              </template>
              <template v-else>
                <v-col cols="12" sm="3" class="flex-grow-0 flex-shrink-0 mb-6 text-center d-flex flex-column justify-center">
                  Sie haben noch keinen Check durchgeführt bzw. noch kein Siegel erhalten.
                </v-col>
              </template>
              <v-col>

                <div class="grid">
                  <v-hover v-slot="{ hover }">
                    <v-card
                        class="py-6 d-flex align-center justify-center"
                        :class="{ 'grey lighten-5': hover }"
                        :elevation="1"
                    >
                      <v-card-title class="px-0">
                        <a :href="'/'+$i18n.locale+'/fragen/ag'" class="d-flex flex-column align-items-center text-decoration-none">
                          <v-icon color="green" style="font-size: 120px" class="mb-2">mdi-forum</v-icon>
                          Check
                        </a>
                      </v-card-title>
                    </v-card>
                  </v-hover>

                  <v-hover v-slot="{ hover }">
                    <v-card class="py-6 d-flex align-center justify-center"
                            :class="{ 'grey lighten-5': hover }"
                            :elevation="1"
                    >
                      <v-card-title class="px-0">
                        <a :href="'/'+$i18n.locale+'/search'" class="d-flex flex-column align-items-center text-decoration-none">
                          <v-icon color="green" style="font-size: 120px" class="mb-2">mdi-magnify</v-icon>
                          Maßnahmensuche
                        </a>
                      </v-card-title>
                    </v-card>
                  </v-hover>

                  <v-hover v-slot="{ hover }">
                    <v-card class="py-6 d-flex align-center justify-center"
                            :class="{ 'grey lighten-5': hover }"
                            :elevation="1"
                    >
                      <v-card-title class="px-0">
                        <a :href="'/'+$i18n.locale+'/ergebnisse'" class="d-flex flex-column align-items-center text-decoration-none">
                          <v-icon color="green" style="font-size: 120px" class="mb-2">mdi-playlist-check</v-icon>
                          Ergebnisse
                        </a>
                      </v-card-title>
                    </v-card>
                  </v-hover>

                  <v-hover v-slot="{ hover }">
                    <v-card class="py-6 d-flex align-center justify-center"
                            :class="{ 'grey lighten-5': hover }"
                            :elevation="1"
                    >
                      <v-card-title class="px-0">
                        <a :href="'/'+$i18n.locale+'/siegel'"  class="d-flex flex-column align-items-center text-decoration-none">
                          <v-icon color="green" style="font-size: 120px" class="mb-2">mdi-seal</v-icon>
                          Siegel und Validierung
                        </a>
                      </v-card-title>
                    </v-card>
                  </v-hover>

                  <v-hover v-slot="{ hover }">
                    <v-card class="py-6 d-flex align-center justify-center"
                            :class="{ 'grey lighten-5': hover }"
                            :elevation="1"
                    >
                      <v-card-title class="px-0">
                        <a :href="'/'+$i18n.locale+'/profile'" class="d-flex flex-column align-items-center text-decoration-none">
                          <v-icon color="green" style="font-size: 120px" class="mb-2">mdi-account-details</v-icon>
                          Profil
                        </a>
                      </v-card-title>
                    </v-card>
                  </v-hover>
                </div>

              </v-col>
            </v-row>
          </div>
      </v-card>

      <v-card class="mt-3">
        <v-card-text>
          <h2 class="my-4">Kontakt</h2>
          <v-row>
            <v-col>
              <p>Haben Sie eine Frage zur Umsetzung von familienfreundlichen Maßnahmen? Haben Sie Anmerkungen, Kritik oder Inspirationen? Wir freuen uns auf Ihre Nachricht oder Ihren Anruf!*</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-card elevation="0">
                <v-row class="pa-4">
                  <v-col cols="12" sm="6">
                    <v-img :src="require('../assets/Julius_Kunath.jpg')" alt=""></v-img>
                  </v-col>
                  <v-col class="text-center text-sm-left">
                    <h3>Julius Kunath</h3>
                    <p class="text-caption">{{ $t('home.jk_position')}} pas <span class="font-italic">digital</span></p>
                    <p><strong class="text-caption text--secondary">{{ $t('home.phone_number') }}</strong><br>
                      <a href="tel:+493514250294">+49 351 42502-94</a>
                    </p>
                    <p><strong class="text-caption text--secondary">{{ $t('home.email') }}</strong><br>
                      <a href="mailto:julius.kunath@bsw-mail.de">julius.kunath@bsw-mail.de</a></p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-3">
        <v-card-text class="pt-2">
          <v-btn outlined dark @click="logout" color="green" class="mt-3">{{ $t('login.logout') }}</v-btn>
        </v-card-text>
      </v-card>
    </template>
		
		<v-card v-else>
			<v-card-title><div class="headline">{{ $t('login.header') }}</div></v-card-title>
			<v-card-text class="pt-4">
				<div>
					<v-form @submit="login" v-model="valid" ref="form">
						<v-alert type="error" outlined v-if="error">
							{{ error_msg }}
							<span v-if="verify_btn">
								{{ $t('login.verify_text') }}
								<br>
								<v-btn class="mt-3 verify-button" outlined light color="red" @click="sendVerifyMail">{{ $t('login.send') }}</v-btn>
							</span>
						</v-alert>
						<v-text-field
							:label="$t('login.label_username')"
							v-model="username"
							:rules="usernameRules"
							required
						></v-text-field>
						<v-text-field
							:label="$t('login.label_password')"
							v-model="password"
							type="password"
							:rules="passwordRules"
							required
						></v-text-field>
						<v-layout>
							<v-btn type="submit" @click="login" class="primary mt-3">{{ $t('login.login') }}</v-btn>
							<v-btn :href="'/'+$i18n.locale+'/reset-password'" text small class="mt-3">{{ $t('login.reset_password') }}</v-btn>
						</v-layout>
					</v-form>
				</div>
			</v-card-text>
			<v-divider class="mx-4"></v-divider>
			<v-card-text class="pt-4">
				<div>{{ $t('login.label_register') }}</div>
				<v-btn outlined dark :href="'/'+$i18n.locale+'/register'" color="green" class="mt-3">{{ $t('login.register') }}</v-btn>
			</v-card-text>
		</v-card> 
	</div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex'

export default {
	components: {
	},
	data: () => ({
    siegel: false,
		error: false,
		error_msg: '',
		verify_btn: false,
		valid: false,
		password: '',
		passwordRules: [],
		username: '',
		usernameRules: [],
	}),
	methods: {
		...mapActions(['setLogged', 'setAccessToken', 'setName']),
    copyCode: function () {
      navigator.clipboard.writeText(this.siegel.code);
    },
    downloadSiegel: function () {
      axios({
        url: this.siegel.img,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'siegel.png');
        document.body.appendChild(link);
        link.click();
      });
    },
		login: function (e) {
		  e.stopPropagation();
		  e.preventDefault();
			if (this.$refs.form.validate()) {
				axios.post('/api/login', {
					username: this.username,
					password: this.password,
					lang:this.$i18n.locale
				})
				.then(res=>{
					if(res.data.token!== undefined) {
						this.error = false;
						localStorage.setItem('access_token', res.data.token);
						localStorage.setItem('name', res.data.name);
						localStorage.setItem('logged', true);
						this.setAccessToken(res.data.token);
						this.setName(res.data.name);
						this.setLogged(true);
						if(this.lastCheck) {
							this.$router.push('/'+this.$i18n.locale+'/fragen/ag');
						}
					} else {
						this.error_msg = res.data.error;
						this.error = true;
						this.verify_btn = res.data.verify_btn !== undefined;
					}
          // eslint-disable-next-line no-unused-vars
				}).then(res=>{
          axios.post('/api/getSiegel', {lang: this.$i18n.locale})
              .then(response => {
                this.siegel = response.data.siegel
              })
        })
				.catch(function() {
					this.error_msg = this.$t('login.error_msg');
					this.error = true;
					this.verify_btn = false;
				});
			}
		},
		logout: function () {
			localStorage.removeItem('access_token');
			localStorage.removeItem('name');
			localStorage.removeItem('logged');
			this.setAccessToken('');
			this.setName('');
			this.setLogged('');
			this.verify_btn = false;
			this.error = false;
		},
		sendVerifyMail: function() {
			if (this.$refs.form.validate()) {
				axios.post('/api/email/resend', {
					username: this.username,
					password: this.password,
					lang:this.$i18n.locale
				})
				.then(res=>{
					this.error_msg = res.data.msg;
					this.error = true;
					this.verify_btn = false;
				})
				.catch(function() {
					this.error_msg = this.$t('login.error_msg');
					this.error = true;
					this.verify_btn = false;
				});
			}
		},
	},
	mounted() {
    document.title = 'Login - ' + this.$t('general.page_title');
		this.error_msg = this.$t('login.error_msg');
		this.passwordRules = [
			(v) => !!v || this.$t('login.passwordRules'),
		];
		this.usernameRules = [
			(v) => !!v || this.$t('login.usernameRules'),
		];
    if (this.logged) {
      axios.post('/api/getSiegel', {lang: this.$i18n.locale})
      .then(response => {
        this.siegel = response.data.siegel
      })
    }
	},
	computed: {
		...mapState(['logged', 'name', 'lastCheck'])
	},
};
</script>
<style scoped>
/deep/ .verify-button .v-btn__content { white-space: normal !important; flex: auto !important; }
.verify-button.verify-button.verify-button.verify-button.verify-button{ height: auto; min-height: 36px; }

  .v-card a {
    word-break: break-word;
    text-align: center;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  @media (min-width: 600px) {
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }
  }
</style>
